import { Spin, ConfigProvider } from 'antd'
import React from 'react'

import LoadingIcon from '@/assets/loading.png'

import Header from './components/Header'
import Nav from './components/Nav'
import s from './global.scss'
import { useMediaScreen } from './lib/hooks/useMedia'

export default () => {
  const { isMobile, isIpad } = useMediaScreen()

  return (
    <div style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, margin: 'auto' }} className="df jcc aic">
      {!isMobile && (
        <div style={{ position: 'absolute', top: 0, width: '100%' }}>
          <Header>
            <Nav />
          </Header>
        </div>
      )}
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#d9c099',
          },
        }}
      >
        {/* <Spin /> */}

        <div className={s.page_loading}>
          <img src={LoadingIcon} alt="logding" style={{ width: 30, height: 30 }} />
          <div className={s.process_wrap}>
            <div className={s.process}></div>
          </div>
        </div>
      </ConfigProvider>
    </div>
  )
}
