export default {
  'The margin calculation here does not take into account any commission deductions required and opening losses.':
    '此处的初始保证金计算未包括任何需要扣除的手续费用或开仓损失。',
  'Please input Leverage': '请输入杠杆',
  'Please input Exit Price': '请输入平仓价格',
  'Please input Amount': '请输入数量',
  'Please input ROI': '请输入ROI',
  'Please input Entry Price': '请输入开仓价格',
  'Execution Fee': '执行费',
  'This rate of return cannot be achieved': '无法达到该回报率。',
  'Open Fee': '开仓费',
  Indicators: '指标',
  Result: '计算结果',
  'Exit Price': '平仓价格',
  Tradingview: 'Tradingview',
  'Initial Collateral': '初始保证金',
  'No Open Positions': '没有开仓头寸',
  'Confirm Close': '确认',
  'Are you sure you want to close all positions? lt may require multiple wallet':
    '您确定要全部平仓吗? 它可能需要进行多笔钱包操作。',
  'By connecting to the wallet, you agree to {to}. (Last updated on 05/31/2023)':
    '链接到钱包即表示您同意 {to}。 （最后更新于 2023 年 5 月 31 日）',
  'BLEX Arbitrum Goerli Testnet': 'BLEX Arbitrum Goerli Testnet',
  'Keep Leverage {lev}x': '保持杠杆 {lev}x',
  "BLEX Vault accrues 70% of the platform's generated fees. BLEX Foundation accrues 30% of the platform's generated fees.":
    'BLP Vault 是 BLEX 上所有交易的对手方，平台产生的协议 100%由 Vault 捕获。',
  'Purchase BLP to provide liquidity and earn 70% of the platform fee distribution.':
    '购买 BLP 以提供流动性并赚取 100%的平台费用。',
  'View status': '查看状态',
  'Please select a network': '请选择网络',
  Network: '网络',
  'Receive test coins': '领取测试币',
  Language: '语言',
  connected_to: '连接到 {chainName} 网',
  Error: '失敗',
  'hide error': '隐藏',
  'show error': '展示错误',
  'The system is currently busy': '系统繁忙，请稍后重试',
  'Transaction sent.': '请求已发送。',
  'Transaction completed!': '请求发送成功，请耐心等待执行。',
  'Close All': '一键平仓',
  status: '状态',
  'In the trading module, you can open a position by selecting a pair to go long or go short, and setting the amount and quantity you want to buy. After you open a position, you can see all the information about your position in the trading record.':
    '在交易模块中，您可以选择要做多或做空的货币对，并设置要买入的金额和数量，从而建立头寸。开仓后，您可以在交易记录中查看有关仓位的所有信息。',
  'BLEX {testnet} Testnet': 'BLEX {testnet} 测试网',
  'Website Pickup': '网站领取',
  'New testnet contracts will be deployed this week on the Arbitrum Goerli Testnet, testnet data will be cleared. For testnet users, positions can be closed beforehand if it is desired to retain the testnet funds in the position collateral.':
    '新的测试网合约将于本周在 Arbitrum Goerli 测试网上部署，测试网数据将被清除。 对于测试网用户，如果希望将测试网资金保留在仓位抵押品中，可以提前平仓。',
  'Edit Referral Code': '编辑推荐码',
  'Ends at': '结束于',
  'Starts in': '开始于',
  'Decentralized Perpetual Exchange': '去中心化永续交易所',
  'Please select a language': '请选择语言',
  USDT: 'USDT',
  'Funding Fee / Day:': '资金费 / 天：',
  'Using Friendly': '使用友好',
  'Trading Smooth': '交易顺畅',
  copy: '链接已复制到剪贴板。',
  'Stake BLE and earn Escrowed BLEX rewards and x% of the platform fee distribution.':
    '质押 BLE 可获得 esBLEX 奖励和 x% 的平台费用。',
  'BLE Vault APR': 'BLE 金库年收益率',
  'Coming Soon': '即将上线',
  Telegram: '电报',
  'in the last 24h': '过去24小时',
  'Purchase BLP to provide liquidity and earn 100% of the platform fee distribution.':
    '购买 BLP 以提供流动性并赚取 100%的平台费用。',
  Referral: '返佣',
  'Dear users, you can execute your trading strategy on BLEX without KYC while controlling of your own assets. Start a silky journey of decentralized perpetual contract trading immediately!':
    '亲爱的用户，无需 KYC、自掌握资产，您即可在 BLEX 上执行您的交易策略。立即开启丝滑的去中心化永续合约交易之旅吧！',
  'By binding an invitee, or becoming an invitee, you can get the corresponding rebate bonuses and rebate discounts. The bonus or discount will be automatically paid to your wallet every week.':
    '绑定受邀者或成为受邀者，即可获得相应的返佣奖金和交易折扣。奖金或折扣将每周自动存入您的钱包。',
  Staking: '质押',
  'You can purchase $BLP revenue-added products from the platform, and you can withdraw the proceeds to your wallet at any time after purchase.':
    '您可以在平台上购买 $BLP 收益增值产品，购买后可随时将收益提现到您的钱包。',
  Trading: '交易',
  Connections: '链接钱包',
  'Select a wallet supported by BLEX by clicking the "Link Wallet" button in the navigation bar. After linking wallets select a network supported by BLEX and enjoy BLEX!':
    '点击导航栏中的 "链接钱包"按钮，选择 BLEX 支持的钱包。链接钱包后，选择 BLEX 支持的网络即可享受 BLEX 的交易！',
  'No Open Orders': '无未完成订单',
  'BLEX T&C': 'BLEX条款及细则',
  'No History': '暂无记录',
  Hide: '隐藏',
  'Bind a referral code to get up to 10% trading discount. The trading discount will be returned in USDT every Wednesday.':
    '绑定推荐代码可获得高达10%的交易折扣，交易折扣将于每周三以 USDT 返还。',
  'Receive Discounts': '获取折扣',
  'Trading Discount': '交易折扣',
  'Bind Referral Code': '绑定推荐码',
  'Input a referral code to benefit from trade discount.': '输入推荐码以享受交易折扣。',
  'Get discounts on transaction fees when you make a trade.': '进行交易时获得交易手续费折扣。',
  'The discounts will be paid out in USDT every Wednesday.': '交易折扣将于每周三以 USDT 返还。',
  'Sorry, the page you visited does not exist': '抱歉，您访问的页面不存在',
  Successful: '成功',
  'Bridge ETH to Arbitrum': '将 ETH 跨链桥接至 Arbitrum',
  'There is not enough ETH in your account on Arbitrum to send this transaction.':
    '您在Arbitrum上的账户中没有足够的 ETH 来发送此交易。',
  'Transaction failed due to RPC error.': '由于 RPC 错误，交易失败。',
  'Please try changing the RPC url in your wallet settings.': '请尝试在您的钱包设置中更改RPC URL。',
  'Transaction failed': '交易失败',
  success: '成功',
  'Share your referral code to receive up to 15% trading rebate. Rebates will be paid out in USDT every Wednesday.':
    '分享您的推荐代码即可获得高达 15% 的交易返佣。 返佣将于每周三以 USDT 返还。',
  'Referral Code:': '推荐码：',
  'Index Perpetual Exchange': '指数永续交易所',
  Request: '请求 {symbol}',
  'Drops are limited to1 request in 8 hours.': '8 小时内仅限 1 次领取。',
  FollowTwitter: '关注 Twitter 和 Telegram 以获取更多{marketName}。',
  'Wallet Address:': '钱包地址：',
  'Total Balance (USDT)': '总余额（USDT）',
  'Short Open Interest: {value}': '空头未平仓头寸：{value}',
  'Long Open Interest: {value}': '多头未平仓头寸：{value}',
  'Open Interest Balance': '未平仓头寸余额',
  'BLEX is a decentralized exchange allowing trading without the need for a username or password. The platform uses an aggregate price feed which reduces the risk of liquidations from temporary wicks.':
    'BLEX 是一个去中心化的交易所，无需用户名或密码即可进行交易。其基础是一个安全、公平和有效的指数价格模型。',
  'How do BLEX Trade work?': '怎么在 BLEX 交易？',
  LONG: '多',
  SHORT: '空',
  'Price Above Liq. Price': '价格高于清算价',
  'Price Below Liq. Price': '价格低于清算价',
  Slider: '滑块',
  Customize: '自定义',
  'Your wallet is not connected to': '您的钱包未链接到 {chainName}',
  'Sell BLP': '出售BLP',
  'Buy BLP': '购买BLP',
  'Selling...': '出售中...',
  'Buying...': '购买中...',
  'Update Notice': '更新通知',
  'Considers settled (closed) positions': '考虑已结算（平仓）头寸',
  'Fees are not factored into PnL': '费用不计入损益',
  Volume: '交易量',
  'Last Month': '上个月',
  'Last 2 Months': '过去 2 个月',
  'Last 3 Months': '过去 3 个月',
  Users: '用户',
  'Users Actions': '用户操作',
  'Traders Profit vs. Loss': '交易者盈利和亏损',
  'Traders Net PnL': '交易者净盈利和亏损',
  'Total Users': '所有用户',
  'BLP Pool': 'BLP池',
  'Total Fees': '总费用',
  Analytics: '分析',
  '+$0': '+$0',
  "The BLP Vault is the counterparty to all trades on BLEX and the vault captures 100% of the platform's generated fees.":
    'BLP Vault 是 BLEX 上所有交易的对手方，平台产生的协议 100%由 Vault 捕获。',
  Status: '状态',
  'What is BLEX':
    'BLEX旨在建立一个去中心化的永续交易所协议。其团队成员来自顶级CEX，知名对冲基金和区块链公司。BLEX的治理将由BLEX DAO确定。BLEX在新加坡和香港设有办事处，DAO成员通过远程工作从世界各地进行贡献。我们期待您成为BLEX的成员。',
  earnTip: '提示：交易者和 LP 都有损失风险。在参与 BLEX 协议之前，请确保熟悉细节。',
  'Media Kit': '媒体工具',
  BugBounty: '赏金计划',
  'I have understood': '我已明白',
  'We recognize that you are trying to access BLEX from a China IP address, please note that per our':
    '我们发现您正在尝试从中国 IP 地址访问 BLEX，请注意，根据我们的',
  ', we are unable to serve any users from an {regionName} IP.': '，我们无法为任何来自中国 IP 的用户提供服务。',
  'footer.ifYouHaveAnyQuestions': '如果您对我们产品的功能有任何疑问，我们很乐意与您交谈，请联系我们。',
  'footer.products': '产品',
  'Referral Terms': '推荐条款',
  'footer.reservedRights': '© 2023 BLEX 版权所有。',
  'Welcome to BLEX': '欢迎来到BLEX',
  'wallet.connect': '链接以登录或创建帐户',
  'wallet.lastUpdated': '链接到钱包即表示您同意 BLEX 条款和协议。 （最后更新于 2023 年 5 月 31 日）',
  'Perpetual Exchange': '永续交易所',
  'Perpetual Trading': '永续交易',
  'By staking, LPs can earn up to 70% of the trading fees and capture the value of the protocol.':
    '通过质押，流动性提供者可以获得高达 70% 的交易费用并获取协议的价值。',
  'Earn Now': '立即赚取',
  'View more': '查看更多',
  'Explore The Endless': '探索无尽',
  'Possibilities With BLEX': 'BLEX 的可能性',
  'BLEX is an on-chain decentralized exchange for perpetual futures, ensuring secure funds and transparent data.':
    'BLEX是永续合约链上去中心化交易所，保证资金安全、数据透明。',
  'Smooth Trading': '丝滑交易',
  'Traders can open positions with zero spread using the index price, while the LP vault provides exceptional depth.':
    '交易者可以使用指数价格以零点差开仓，而有限合伙人金库则提供卓越的深度。',
  'Get Started': '开始使用',
  'Trading is available on both the web and mobile app, with easy wallet integration. Joining in only requires one simple step.':
    '交易可在网络和移动应用程序上进行，并可轻松集成钱包。 加入只需一个简单的步骤。',
  'Always at your side': '永远在你身边',
  'Join the BLEX community now!': '立即加入 BLEX 社区！',
  Mint: '铸造',
  'We are continuously launching new perpetual contracts markets.': '我们不断推出新的永续合约市场。',
  'Stake BLE and BLP to earn rewards.': '质押 BLE 和 BLP 以获得奖励。',
  'Get test tokens': '获取测试令牌。',
  'Wallet Address': '钱包地址',
  'Share Revenue': '分享收益',
  'Txn failed.': '发送失败。',
  ', we are unable to serve any users from an ': '，我们无法为任何用户提供服务',
  Switch: '选择',
  'Connect Wallet': '链接钱包',
  'Drops are limited to 1 request in 8 hours.': '8 小时内仅限 1 次领取。',
  'Connect your wallet': '链接你的钱包',
  Home: '首页',
  'Bug bounty': '赏金计划',
  Test: '测试',
  'Considers Settled (Closed) Positions': '考虑已结算（平仓）头寸',
  'Fees Are Not Factored Into PnL': '费用不计入损益',
  'The total number of positions opened and closed during the event': '活动之间累计的开仓和平仓总次数',
  Days: '天',
  Hours: '小时',
  Minutes: '分钟',
  Seconds: '秒数',
  PNL: 'PNL',
  'The BLEX bug bounty program is now live! Up to $100,000 in rewards for a key bug report.':
    'BLEX 赏金计划现已上线！ 报告关键漏洞可获得高达 100,000 美元的奖励。',
  'Assets Under Management': '管理资产',
  Service: '服务',
  'Stake BLE and earn Escrowed BLEX rewards and 30% of the platform fee distribution.':
    '质押BLE以赚取托管BLE奖励和30%的平台费用收入。',
  'Staking to Earn': '质押赚取',
  'Bind a referral code to get up to 10% trading discount. The trading discount will be returned in USDC every Wednesday.':
    '绑定推荐代码可获得高达10%的交易折扣，交易折扣将于每周三以 USDT 返还。',
  'Create referral code and start earning rebates!': '创建推荐码并开始赚取返利！',
  'Invite User': '邀请用户',
  'Share your referral code on social media platforms such as Twitter and Facebook.':
    '在 Twitter 和 Facebook 等社交媒体平台上分享您的推荐码。',
  'Earn Rebates': '赚取返佣',
  'When you successfully invite users to trade, the users can enjoy reduced trading fees and you can receive trading rebates.':
    '当您成功邀请用户交易时，用户可以享受交易手续费的优惠，您还可以获得交易返利。',
  Referrers: '推荐人',
  'Your Active Referral Code': '您的有效推荐码',
  'Please input a referral code to benefit from fee discounts.': '请输入推荐码以享受费用折扣。',
  'Referral code updated failed.': '推荐码更新失败。',
  'Adding referral code failed.': '添加推荐码失败。',
  'Submitting...': '正在提交...',
  'Checking code...': '检查推荐码...',
  Action: '动作',
  'Creating...': '创建中...',
  'Create code failed.': '创建失败',
  'Create Referral Code': '创建推荐码',
  'Rebates earned by this account as a trader.': '该账户作为交易者赚取的折扣。',
  'Volume traded by this account with an active referral code.': '使用此账户推荐码的有效交易量。',
  'You will receive a 5% discount on your opening and closing fees, this discount will be airdropped to your account every Wednesday.':
    '这是您绑定推荐者的等级，以及您将获得的开平仓费用的折扣比例，折扣在每周三空投到账户。',
  'Total Claimable Reward': '总可领取奖励',
  'Claim all': '全部提取',
  'Stake BLE and earn Escrowed BLEX rewards and 70% of platform fees distributed.':
    '质押BLE以赚取托管BLE奖励和30%的平台费用收入。',
  'Your Wallet': '你的钱包',
  'Your Staked': '你的质押',
  'Total Staked': '总质押',
  'Buy BLE': '购买BLE',
  Stake: '质押',
  Unstake: '取消质押',
  'Provide liquidity by purchasing BLP with USDT. and earn 70% of platform fees distributed.':
    '通过用USDT购买BLP来提供流动性， 并赚取70%的平台费用收入。',
  'BLE Points': 'BLE积分',
  'The fuel for boosting esBLE to BLE.': '将esBLE转化为BLE的燃料。',
  'Buy BPT': '购买BPT',
  'Escrowed BLE': '托管BLE',
  'Convert esBLE to BLE through Boost and Vest.': '通过Boost和Vest将esBLE转换为BLE。',
  Boost: 'Boost',
  Vest: 'Vest',
  'Enjoy BLP Rewards': '获取BLP奖励',
  'Total BLP amount': '总BLP数量',
  'Quantity of NFTs.': 'NFT数量',
  Expand: '展开',
  'Mint successful': '铸造成功',
  'Hurry up stake and enjoy the Rewards.': '赶快质押获取奖励吧。',
  Done: '完成',
  'Owning this NFT entitles you to Enjoy BLP Rewards, Double BPT Income!':
    '拥有此NFT即可享受对应 BLP 奖励，双倍 BPT 收入！',
  'Your Stake': '你的质押',
  'Stop loss': '止损',
  'Take profit': '止盈',
  'Creating Order...': '创建订单中...',
  'Longing...': '做多中...',
  'Shorting...': '做空中...',
  'Liq. Price': '清算价',
  'View Chart': '查看表格',
  Export: '导出',
  price: '价格',
  'Net Value': '净资产',
  'Net Value: Initial Collateral + PnL - Funding Fee - Close Fee': '净资产：初始保证金 + 损益 - 资金费 - 平仓费',
  Liq: '清算',
  'Price below liq. price': '价格低于清算价',
  'Price above liq. price': '价格高于清算价',
  'Slippage should be less than': '滑点应该小于',
  'Slippage should be more than': '滑点应该大于',
  'Creating order...': '创建订单中...',
  'Closing...': '平仓中...',
  'Index Price:': '指数价格：',
  'Close:': '平仓：',
  'Available:': '可用：',
  'Withdrawing...': '提取中...',
  'Depositing...': '充值中...',
  MAX: '最大值',
  Amount: '数量',
  'Size:': '头寸：',
  'Welcome to BLEX {chainName}. Please pay attention to get the testToken first.':
    '欢迎来到BLEX {chainName}。 请先领取测试Token。',
  All: '全部',
  'Open Long': '开多',
  'Open Short': '开空',
  'Close Long': '平多',
  'Close Short': '平空',
  Liquidation: '清算',
  'Partial Liquidation': '部分清算',
  'Partial liquidation': '部分清算',
  'Withdraw Collateral': '提取保证金',
  'Deposit Collateral': '存入保证金',
  '5m': '5分钟',
  '15m': '15分钟',
  '1h': '1小时',
  '4h': '4小时',
  '1d': '1天',
  Executed: '已执行',
  Canceled: '已取消',
  Original: '基础版',
  TradingView: 'TradingView',
  Currency: '币种',
  'Balance:': '余额:',
  'The BLP Vault is the counter party to all trades on BLEX.': 'BLEX 上的所有交易的交易对手方是 BLP Vault。',
  'How do BLEX rewards work?': 'BLEX 奖励如何施行？',
  tradesNoHistory: '暂无历史记录',
  'How do BLEX Earn work?': 'BLEX 奖励如何施行？',
  'About Blex': '关于我们',
  'Connect your wallet to continue': '链接钱包',
  Filter: '筛选',
  'No Record': '无记录',
  'Go trade now!': '即刻交易!',
  Cancle: '取消',
  'Competitions 🔥': '交易大赛',
  Continue: '继续',
  Competitions: '竞赛',
  Decentralized: '去中心化',
  'The index price retrieval is incorrect, please try again later.': '指数价格检索错误，请稍后再试。',
  'User friendly': '用户友好',
  'Event Details>>': '活动详情>>',
  'BLEX Arbitrum Mainnet Launch on Aug 6th!  Reserve now to share the total $10000 Prize Pool.':
    'BLEX Arbitrum 主网将于 8 月 6 日上线！ 立即预约即可分享总计 10000 美元的奖池。',
  'Congrats! Reservation in Success! Continue to split the final $10000 USDT rewards!':
    '恭喜！ 预约成功！ 继续瓜分最终的10000美元USDT奖励！',
  'Reservation Success': '预约成功',
  Reserve: '预约',
  'Countdown to the launch': '上线倒计时',
  Connect: '链接',
  'Enter Referral code': '输入推荐码',
  'Open Interest': '未平仓头寸',
  AnalyticsDesc: '更新于 {time} , {block}区块',
  '24h Change': '24小时变化',
  '24h High': '24h最高价',
  '24h Low': '24h最低价',
  'Buy / Sell BLP': '买/卖BLP',
  IMakeTrades: '我已经在BLEX完成了 {value} 笔交易，快来加入我吧！',
  'Index Price': '指数价格',
  'Share Position': '分享仓位',
  'PnL After Fees:': '扣除费后的损益：',
  'Open Fee {value}': '开仓费：{value}',
  'Close Fee {value}': '关仓费：{value}',
  'Funding Fee {value}': '资金费：{value}',
  'PnL {value}': '损益：{value}',
  'Initial Collateral {value}': '初始保证金：{value}',
  'Use the edit collateral icon to deposit or withdraw collateral.': '使用编辑保证金图标存入或提取保证金。',
  'Funding Fee:': '资金费：',
  'Collateral:  Initial Collateral - Funding Fee': '保证金：初始保证金 - 资金费',
  'Initial Collateral:': '初始保证金：',
  ConfirmLong: '做多 {symbol}',
  ConfirmShort: '做空 {symbol}',
  Confirm: '确认',
  Networks: '网络',
  ExecutionFee: '执行费：{fee}',
  'The execution fee is only charged when the order is executed or canceled due to invalid execution.':
    '只有在订单被执行或因执行无效而被取消时，才收取执行费。',
  OpenFeeLabel: '开仓费：{fee}',
  CloseFeeLabel: '关仓费：{fee}',
  FundingFee: '资金费：{fee}',
  Fees: '费用',
  Size: '大小',
  Available: '可用',
  MinResidualCollateralUSD: '最小剩余抵押品：{USD}美元',
  LeftoverNetValue: '剩余净值低于 ${cleanFee}',
  LeftoverCollateral: '抵押品低于 ${cleanFee}',
  AcceptMinimum: '接受最小值并{action}',
  ExistingIncrease: '您现有的 {isLong} {toSymbol} 限价单有多个',
  ExistingPosition: '您已经拥有一个 {isLong} 仓位，将增加现有仓位的大小。',
  ConfirmOrderShort: '确认 {toSymbol} 空单',
  ConfirmOrderLong: '确认 {toSymbol} 多单',
  'Connected with': '链接到',
  'View on Explorer': '在区块链浏览器中查看',
  'Txn failed': '交易失败',
  View: '查看',
  text: '文本',
  'No assets yet？You can bridge ETH from another network, transfer ETH from an exchange':
    '还没有资产吗？你可以从跨链桥或交易所划转ETH。',
  Homepage: '主页',
  'Trading Volume': '交易量',
  Open: '开仓',
  Rank: '排名',
  Account: '账户',
  '': '',
  'Cum.Size': '累计数量',
  'Cum.Collateral': '累计抵押',
  Prize: '奖品',
  Reward: '奖励',
  'Total Profit': '总利润',
  'The total volume accumulated between the activity': '活动期间累计交易量。',
  'BLP Supply': 'BLP供应量',
  'The total BLP supply accumulated between the activity': '活动期间累计BLP供应量',
  'Prize Pool': '奖金池',
  'The estimated amount distirbuted to all top traders by competition end results':
    '竞赛结束后分配给所有顶级交易者的估计金额。',
  Trades: '交易次數',
  'Rank List': '排行榜',
  'Profit Rank': '利润排名',
  Profit: '利润',
  'Trade ROI Rank': '交易ROI排名',
  'Trade ROI': '交易ROI',
  'Trading Data': '交易数据',
  'Total volume': '总交易量',
  'Fees Paid': '已支付手续费',
  'Trading Fees': '交易手续费',
  'Open/Close Fee': '开仓/平仓手续费',
  'Funding Fee': '资金费用',
  'Read More': '查看更多',
  'Average profit': '平均利润',
  'Average loss': '平均亏损',
  'Address copied to your clipboard': '地址已复制到剪贴板',
  Traders: '交易者',
  'Enter referral code': '输入推荐码',
  'Please input a referral code to benefit from fee discounts': '请输入一个推荐码以获得费用折扣',
  'Save Changes': '保存更改',
  'Referral code updated!': '推荐码已更新！',
  'Referral code updated failed': '更新推荐码失败',
  'Adding referral code failed': '添加推荐码失败',
  'Same as current active code': '与当前推荐码相同',
  Submitting: '正在提交',
  'Checking code': '正在检查代码',
  'Referral code does not exist': '推荐码不存在',
  'Referral link copied to your clipboard': '推荐链接已复制到剪贴板。',
  Creating: '创建中',
  'Enter a code': '输入推荐码',
  'Code already taken': '推荐码已被使用',
  Create: '创建',
  title: '标题',
  desc: '描述',
  'Total Trading Volume': '总交易量',
  'Volume traded by this account with an active referral code': '此账户通过有效推荐码交易的总量',
  'Total Traders Referred': '总推荐交易者数',
  'Rebates earned by this account as a trader': '此账户作为交易者赚取的返利',
  'Total Rebates': '总返利',
  'Tier 1 (5% rebate)': '第一梯队（5% 折扣）',
  'Edit Refferal code': '编辑推荐码',
  'Active Referral Code': '有效推荐码',
  'Tier 1 (5% discount)': '一级（5% 折扣）',
  'You will receive a 5% discount on your opening and closing fees, this discount will be airdropped to your account every Wednesday':
    '您将获得开仓和平仓费用的 5% 折扣，此折扣将于每个星期三空投至您的账户',
  'Tier 1': '一级',
  Rewards: '奖励',
  'Stake USDT and earn rewards from fees generated on the BLEX.': '质押 USDT 并从 BLEX 产生的费用中获得奖励。',
  'Learn More': '了解更多',
  'Enter an amount': '输入一个数量',
  'Insufficient USDT balance': 'USDT余额不足',
  'Insufficient BLP balance': 'BLP余额不足',
  Buy: '购买',
  Sell: '出售',
  'BLP Vault APR': 'BLP Vault 年化收益率',
  'Your stake': '您的质押份额',
  rewarded: '奖励',
  'Pending rewards': '待领取的奖励',
  'Harvest USDT': '收获 USDT',
  'Get Insurance': '获得保险',
  Advanced: '高级选项',
  'Stop Loss': '止损',
  'Take Profit': '止盈',
  Slippage: '滑点',
  'Note that a low allowed slippage, e.g. less than 0.5%, may result in failed orders if prices are volatile.':
    '请注意，允许的滑点较低（如低于0.5%）可能会导致订单在价格波动时失败。',
  'Trading units using stable coins in BLEX such as USD, open position amounts shown are calculated based on the actual coin quantity.Values change with the last price of the symbo':
    '在 BLEX 中使用像 USD 这样的稳定币进行交易，显示的持仓量是基于实际币数量计算的。价值随符号的最后价格而变化。',
  label: '标签',
  'Entry Price': '开仓价格线',
  'Liq.Price': '强平价格',
  Type: '类型',
  Spread: '点差',
  Time: '时间',
  Order: '订单',
  Position: '持仓',
  Mark: '标记',
  'Enter a price': '输入价格',
  'Price above current price': '高于当前价格',
  'Price below current price': '低于当前价格',
  'Stop loss above current price': '止损高于当前价格',
  'Stop loss below current price': '止损低于当前价格',
  'Stop loss below liq.Price': '止损低于强平价格',
  'Stop loss above liq.Price': '止损高于强平价格',
  'Take profit below current price': '止盈低于当前价格',
  'Take profit above current price': '止盈高于当前价格',
  'Take profit below liq.Price': '止盈低于强平价格',
  'Take profit above liq.Price': '止盈高于强平价格',
  'Slippage should be less than %': '滑点应小于 %',
  'Slippage should be more than %': '滑点应大于 %',
  Approving: '正在批准',
  'Incorrect network': '网络错误',
  'Create limit order': '创建限价订单',
  'Available Liquidity': '可用流动性',
  titleId: '标题 ID',
  Overview: '首页',
  More: '更多',
  'Your Trades': '您的交易 {trades}',
  'Your Rank': '您的排名 {tradeROIRank}',
  'Your price': '奖励: {tradeROIRankShow}',
  'Your supply': '您的供应 {yourStake}',
  'Your Volume': '您的成交量 {total}',
  AdvancedStopLess: '当指数价格达到 {stopLoss} USD，它将触发止损，并且预估的PnL将为{stopLossPNL} USD。',
  AdvancedProfit: '当指数价格达到 {takeProfit} USD，它将触发止盈，并且预估的PnL将为 {takeProfit} USD。',
  'Your Rewards': '您的奖励',
  'BLP Vault': 'BLP Vault',
  'Total Supply': '总供应量',
  'BLP Vault balance': 'BLP Vault余额',
  Entry: '进场',
  MinPayUSD: '最低支付：{min}美元',
  MaxPayUSD: '最大订单量：{max}美元',
  MAXLeverage: '最大杠杆：{max}.00x',
  MinLeverage: '最小杠杆：{min}.00x',
  MAXExceeded: '超过最大{toSymbol} {pos}',
  ConnectWallet: '链接钱包',
  Index: '指数',
  Price: '价格',
  Disconnect: '断开链接',
  Perpetual: '永续合约',
  Exchange: '交易所',
  Copy: '复制',
  Download: '下载',
  Twitter: '推特',
  Share: '分享',
  Winrate: '胜率',
  Average: '平均',
  profit: '盈利',
  loss: '亏损',
  Postion: '仓位',
  Net: '净',
  Value: '价值',
  Close: '关闭',
  Collateral: '抵押品',
  Edit: '编辑',
  Limit: '限价',
  Cancel: '取消',
  Fund: '资金',
  Fee: '手续费',
  Submit: '提交',
  fundFeeInfoP1: '资金费率主要用于平衡多头和空头的比例。',
  TermsAndConditions: '服务条款',
  MediaKit: '媒体资源包',
  Approve: '批准',
  'Connect wallet': '链接钱包',
  'Hide Other Pairs': '隐藏其他交易对',
  Referrals: '推荐',
  ReferralsSubTitle: '通过BLEX推荐计划获得费用折扣和奖励返利。',
  CompetitionsSubtitle: '竞赛将表彰获得最高总利润和交易投资回报率的交易者。',
  Governance: '治理',
  Community: '社区',
  Developers: '开发人员',
  Docs: '文档',
  'Contact us': '联系我们',
  'Who We Are?': '我们是谁？',
  'Terms of Service': '服务条款',
  'Trade BTC, ETH and other top cryptocurrencies with up to 100x leverage directly from your wallet.':
    '直接从您的钱包以最高100倍的杠杆交易BTC，ETH和其他热门加密货币。',
  'For trader': '对于交易者',
  'Using their own wallet to do perpetual trading, with 100x (up to) leverage, 0 spread and excellent depth.':
    '使用自己的钱包在100倍杠杆、0滑点和极棒交易深度的交易所进行衍生品交易！',
  Trade: '交易',
  'For vault LP': '用于储备金 LP',
  'Earn trading fee with in stable coin staking. No impermanent loss.': '在稳定币质押中赚取交易费，无不稳定损失。',
  Earn: '赚取',
  'Asset Safety': '资产安全',
  'BLEX is a on-chain DEX of perpetual futures. Fund security and data transparency.':
    'BLEX 是一个永续合约的链上 DEX。资金安全和数据透明。',
  'Avoid IL': '避免不稳定损失',
  'Following the perpetual futures mechanism, the single currency USD LP vault avoids Impermanent Losses.':
    '遵循永续合约机制，单币种 USD LP 储备金避免了不稳定损失。',
  'Value Capture': '价值捕获',
  'Earn up to 70% trading fee via staking. Capture the value of the protocol.':
    '通过质押赚取高达 70% 的交易费。捕获协议的价值。',
  'Trading is available both on WEB and mobile APP. Connecting to the wallet you are using now. Only one step to join in.':
    '在 WEB 和移动 APP 上都可进行交易。链接您正在使用的钱包。只需一步即可加入。',
  learnMore: '了解更多',
  'referrals.question': 'BLEX 推荐计划如何施行？',
  'referrals.answer1': '折扣和返利将每周以 USDT 的形式分发。',
  'referrals.answer2': '推荐计划为交易者提供高达 10% 的折扣，为推荐人提供 15% 的返利。',
  'referrals.table.date': '日期',
  'referrals.table.amount': '数量',
  'referrals.table.transaction': '交易',
  'referrals.table.title': '奖励分配历史记录',
  'referrals.table.no_history': '还没有奖励分配历史记录。',
  'referrals.looksLike': '看起来您没有推荐码。在下面输入代码并点击提交以在链上创建它。',
  'referrals.referralCode': '推荐码',
  'referrals.tradedVolume': '总交易量',
  'referrals.tradersReferred': '已推荐交易者数',
  'referrals.totalRebates': '返利总额',
  referrals: '推荐码',
  'referrals.noCodes': '还没有推荐码。',
  'referrals.ttwitterText': '直接使用钱包进行 ETH/USD 和其他顶级加密货币的多达 100 倍杠杆交易。@blex.io',
  'referrals.codeLenTip': '推荐码不能超过 20 个字符',
  'referrals.title': '输入推荐码',
  'referrals.subTitle': '请输入推荐码以获得费用减免',
  'referrals.btnText': '提交',
  'referrals.editBtnText': '保存更改',
  'referrals.successMsg': '推荐码已更新！',
  'CancelReason.Padding': '未定义',
  'CancelReason.Liquidation': '强制平仓并自动取消',
  'CancelReason.PositionClosed': '仓位已关闭并自动取消',
  'CancelReason.TpAndSlExecuted': '止盈止损已执行并自动取消',
  'CancelReason.SysCancel': '合约执行时出现错误',
  'CancelReason.PartialLiquidation': '部分强制平仓并自动取消',
  above: '高于',
  below: '低于',
  long: '做多',
  short: '做空',
  tp: '止盈',
  sl: '止损',
  and: '和',
  OrderType: '订单类型',
  'Orders.noPos': '无未平仓头寸，必须先开仓才能下单。',
  'Orders.orderSize': '订单大小大于头寸大小，只有在存在头寸的情况下才能下单。',
  'Orders.limitReached': '触发订单已达到上限，止损/止盈订单将无法创建。',
  'Orders.stopLoss': '止损价格 {triggerAbove} 爆仓价格，止损订单将无法创建。',
  'Orders.orderWithType': '此订单为 {type} 订单。',
  'Orders.orderSet': '此订单已设置 {TP} {and} {SL} 订单。',
  'Orders.pnlText': '当指数价格达到 {triggerPrice} 美元时，将触发 {type} 订单，预计盈亏将为 {PNL} 美元。',
  'Orders.tpOrSL': '止盈/止损',
  'Orders.editOrderTitle': '编辑 {type} 订单',
  Positions: '持仓',
  History: '历史',
  _Orders: '订单',
  orderPrice: '订单价格',
  indexPrice: '指数价格',
  tpSL: '止盈止损',
  cancelAll: '取消全部',
  WARNING: '警告',
  liqPrice: '强制平仓价格',
  enterPrice: '输入一个价格',
  priceAboveCurrent: '价格高于当前价格',
  stopLossAboveOrder: '止损高于订单价格',
  stopLossBelowLiq: '止损低于爆仓价格',
  takeProfitBelowOrder: '止盈低于订单价格',
  priceBelowCurrent: '价格低于当前价格',
  stopLossBelowOrder: '止损低于订单价格',
  stopLossAboveLiq: '止损高于强平价格',
  takeProfitAboveOrder: '止盈高于订单价格',
  priceBelowLiq: '价格低于爆仓价格',
  priceAboveLiq: '价格高于爆仓价格',
  updatingOrder: '正在更新订单...',
  updateOrder: '更新订单',
  Pair: '交易对',
  'position.noOpenPositions': '没有开仓头寸',
  'position.sharePosition': '分享仓位',
  'position.warinitePositions': '此头寸扣除借款费用后拥有低保证金，存入更多保证金以降低头寸的清算风险。',
  Opening: '开仓中...',
  Closing: '平仓中...',
  editCollateral: '修改抵押品',
  entryPrice: '开仓价格',
  netValue: '净值',
  Market: '市场',
  Trigger: '触发价',
  welcomeTo: '欢迎来到{type}。',
  trustedByUser: '已经得到{uniqueUsersTotal}名交易者的信任。',
  addedLastWeek: '上周新增：{money}',
  tierAndRebate: '等级 {level}（{amount}% 返利）',
  tierAndDiscount: '等级 {level}（{amount}% 折扣）',
  closeIsLongSymbol: '平 {isLong} {symbol}',
  'Stake USDT and earn rewards from fees generated on the BLEX': '质押 USDT 并从 BLEX 产生的费用中获得奖励',
  'The BLP Vault is the counter party to all trades on BLEX': 'BLEX 上的所有交易的交易对手方是 BLP Vault',
  "BLEX Vault accrues 70% of the platform's generated fees.BLEX Foundation accrues 30% of the platform's generated fees":
    'BLEX Vault将获得了平台产生的70%的费用，而BLEX 基金会获得了平台剩下产生的30%的费用。',
  'Hello!': '你好',
  '24h Trades': '24小时交易',
  '24h Volume': '24小时交易量',
  'No data': '暂无数据',
  'Up to 20%': '高达20%',
  'Stake USDT and earn rewadrs from fees generated on the BLEX': '抵押USDT并从BLEX产生的费用中获得奖励。',
  'Lowest transaction fees': '最低交易费用',
  'Referral code added!': '推荐码已添加',
  'Create code failed': '推荐码创建失败',
  'Generate Referral Code': '生成推荐码',
  'No rewards distribution history yet': '暂无奖励分配历史记录',
  'Trading units using stable coins in BLEX such as USD, open position amounts shown are calculated based on the actual coin quantity. Values change with the last price of the symbol.':
    '在 BLEX 中使用稳定币（如 USD）交易时，显示的持仓金额是基于实际币种数量计算的。该数值会随着交易对最新价格的变化而变化。',
  'You have an active trigger order that could impact this Position': '您有一个正在生效的触发订单，可能会影响此持仓',
  'You have multiple active trigger orders that could impact this Position':
    '您有多个正在生效的触发订单，可能会影响此持仓',
  'You have an active Limit Order that could impact this Position': '您有一个正在生效的限价订单，可能会影响此持仓',
  'Creating Order': '正在创建订单',
  Longing: '开多中',
  Shorting: '开空中',
  'Position Size': '仓位大小',
  Leverage: '杠杆',
  Side: '方向',
  Increase: '增加',
  Long: '做多',
  Short: '做空',
  'Price below liq.price': '价格低于清算价格',
  'Price above liq.price': '价格高于清算价格',
  'Max close amount exceeded': '最大平仓数量超过限制',
  'Creating order': '正在创建订单',
  'Create order': '创建订单',
  'Keep Leverage': '保持杠杆',
  Receive: '收到',
  Deposit: '存款',
  Withdraw: '取款',
  'Invalid liq.price': '无效的清算价格',
  Withdrawing: '正在提款',
  Depositing: '正在存款',
  Pay: '支付',
  'Bug Bounty': '赏金计划',
  'Submit a Bug': '提交漏洞',
  Balance: '余额',
  'Collateral: Initial Collateral - Funding Fee': '保证金：初始保证金 - 资金费',
  'I have made trades on BLEX, come with me!': '我已经在BLEX完成了 {trades} 笔交易，快来加入我吧！',
  '+$0.00': '+$0.00',
  ReferralTerms: '推荐条款',
  'Confirm Limit Order': '确认限价订单',
  'Create Order': '创建订单',
  Prev: '上一页',
  'Share Time': '分享时间',
  Next: '下一页',
  'Next Funding Fee': '下次资金费',
  Countdown: '倒计时',
  'Waiting for update': '等待更新',
  'The payment rate exchanged between the long and short positions for the next funding. If the funding rate is positive, pay funding fee. If negative, receive funding fee.':
    '做多仓位和做空仓位在下次资金费率时间点须交换的资金费率。费率为正，支付资金费。费率为负，收取资金费。',
  'Your network is not good, please reconnect your network': '您的网络不正常，请重新连接网络',
  'Total Volume': '总交易量',
  'Top{no}': '第{no}名',
  'Share {no}': '瓜分{no} 奖池',
  'Futures Trading Competition': '合约交易大赛',
  'Current total trading volume (USDT)': '当前总交易量（USDT）',
  'Ends in': '距离结束',
  'Withdraw collateral': '提取保证金',
  'Deposit collateral': '存入保证金',
  'Open Orders': '当前委托线',
  'Order History': '历史委托',
  Bar: '美国线',
  Candle: 'K线图',
  Line: '线形图',
  Area: '面积图',
  'Hollow Candle': '空心K线图',
  Baseline: '基准线',
  'Hi-Lo': '高低',
  O: '开',
  H: '高',
  L: '低',
  C: '收',
  'Event Rules >': '活动规则 >',
  'Competitions will reward traders based on the volume target and rank.':
    '比赛将根据交易量目标和排名对交易者进行奖励。',
  'Trade Now': '立即交易',
  'Current Total Volume': '当前交易总额',
  'TP/SL Keep Leverage': 'TP/SL 保持杠杆倍数',
  Leaderboard: '排行榜',
  ROI: 'ROI',
  Million: '百万',
  PositionStatusExecuted: '已执行：指这笔订单已执行成功。',
  PositionStatusRequest: '请求：指这笔订单请求成功，但需耐心等待订单执行。',
  PositionStatusCancelled: '已取消：指这笔订单已取消。',
  '15-minute waiting period for selling purchased BLP.': '购买的BLP出售有15分钟等待时间。',
  'System is currently upgrading...': '系统正在升级中...',
  'Copy Link': '复制链接',
  'Referral Code copied to your clipboard': '推荐码已复制到您的剪贴板',
  'Total Discount': '总折扣',
  'Total Rebate': '总返利',
  'Total Discounts Earned by this Account as a Trader.': '该账户作为交易者赚取的总折扣。',
  'Total Trading Volume with Valid Referral Codes for this Account.': '该账户绑定有效推荐码的交易总量。',
  'Total Rebates Earned by this Account as a Referrer.': '该账户作为推荐者赚取的总返利。',
  'Total Trading Volume of Recommended Traders for this Account.': '该账户推荐交易者的交易总量。',
  'Total Number of Traders Recommended by this Account.': '该账户推荐的交易者总数。',
  Doc: '文档',
  Audit: '审计',
  'Transaction Records': '交易记录',
  'Reward Records': '奖励记录',
  'Total Amount': '总金额',
  'Total Buy Amount': '买入总金额 = 价格 * 数量 + 手续费',
  'Total Sell Amount': '卖出总金额 = 价格 * 数量 - 手续费',
  'Transaction was cancelled.': '您已拒绝授予权限，请检查应用程序。',
  'Market Order Beyond the slippage price and canceled.': '市价单价格超过滑点价格。',
  handCancel: '您已拒绝授予权限，请检查应用程序。',
  'System Cancel': '系统取消。',
  'Error encountered during contract execution(1000).': '系统错误。',
  Skip: '跳过',
  'Phase II': '二期',
  'Exclusive benefits and rewards for you to unlock, and special rewards for new users!':
    '独家福利等你来解锁，更有新用户专属奖励！',
  'Will Start In': '开始于',
  'So Stay Tuned!': '敬请期待！',
  'Invite Contest': '邀请交易赛',
  'Invite to earn $2,300': '邀请赢$2,300奖励',
  'Trading Volume Contest': '交易量评比赛',
  'Trade Futures Volume earn $46,500': '交易赢$46,500奖励',
  'Trading ROI Contest': '胜率排名赛',
  'Trade Futures ROI to earn $1,200': '收益评比赢$1,200奖励',
  'Trading Contest': '交易大赛',
  'During the event period, the top 50 future traders will share $46,500 USDT prize pool, based on total trading volume reached on the blex exchange, and their future trading volume.':
    '活动期间，官方将根据blex平台总交易量及用户交易量排名来瓜分共计 $46,500 USDT奖池，具体规则如下。',
  'Detail Rules': '详细规则',
  'When Total Trading Volume Reaches': '当交易总额达到',
  'During the event period, invite your friends to complete a future trading in any value via your invitation code/link. Both the inviting user and the invited user will get $2 USDT. The reward is limited to the first 500 valid invitations, first come first served.':
    '邀请人通过邀请码/邀请链接邀请好友在BLEX去中心化交易所完成1笔任意金额的合约交易，即视为有效邀请。每完成一次有效邀请，邀请人和被邀请人均可获得{value}奖励，奖励仅限前500位有效邀请，先到先得。',
  'Note: The maximum reward for an individual user is $20 USDT.': '注意：单人最高可得 {value} 的邀请奖励。',
  'Invite Now': '立即邀请',
  'ROI Rank': 'ROI（收益率）排名',
  'During the event, $1,200 USDT will reward users according to their future trading profitability (ROI) rank. Both the top3 and top 498-500 will get $200 USDT each in this session.':
    '活动期间，用户在BLEX平台开仓交易，按照交易盈利率（ROI）排名，活动结束结算，排名 {value1} 和 {value2} 的用户，均可获得 {value3} 奖励。',
  Notices: '注意',
  '1.Users must meet the requirements during the event to be eligible for the rewards.':
    '1.用户必须满足活动要求方有资格获得奖励。',
  '2.The rewards will be issued within 7 working days after the event.':
    '2.所有奖励将在活动结束后 {value} 个工作日内发放。',
  '3.The trading volume only includes Futures trading.Trading Volume = Opened Positions + Closed Positions.':
    '3.交易量计算仅限于合约交易。 {value}',
  '4.Users need to accumulate at least 5U to win.': '4.用户需累计至少 {value} 方可发奖。',
  '5.No spam is allowed, or you will be disqualified.': '5.禁止任何违规刷量行为，一经发现，将取消获奖资格。',
  '6.BLEX reserves the right to the final interpretation of this event.': '6.本次活动解释权归blex官方所有。',
  '7.If you have any questions, please contact our admin via': '7.如有任何问题，请加入社群开票咨询管理员寻求帮助',
  'Your ROI': '你的ROI(收益率）',
  'ROI = (Profit + UnProfit) / Cum.Collateral': '收益率ROI =（实现盈亏+未实现盈亏）/ 累计保证金',
  'Cum.Collateral = Total Opening position + Total Deposit Collateral': '累计保证金 = 累计开仓量+累计充值保证金',
  'Volume = Total Size (Open Size + Close Size)': '交易量 = 仓位总和（开仓量 + 平仓量）',
  'Trading Volume = Opened Positions + Closed Positions.': '交易量 = 开仓量 + 平仓量。',
  'discord or telegram': 'DC',
  'In addition, the top 10 inviting users can get an additional $30 USDT reward after the event ends.':
    '活动结束，有效邀请排名 {value} 的用户，可再额外获取 {value1} 奖励。',
  '3.The trading volume only includes Futures trading.': '3.交易量计算仅限于合约交易。',
  Top3: '前3名',
  'Bottom 3': '后3名',
  'no list data': '暂无榜单信息',
  'mail notice': '邮箱通知',
  'mail info Add': '钱包中心新增“邮箱通知”功能，开启后可及时接收有关仓位的相关信息~',
  'open mail subscrip': '此处同样可开启“邮箱订阅”功能',
  'got it': '我知道了',
  'provide mail account': '请提供您常用的邮箱账号，我们将随时向您发送与仓位相关的预警提示。',
  'please enter email account': '请输入邮箱账号',
  'error style re enter': '邮箱格式不正确，请重新输入',
  'confirm open': '确认开启',
  'close mail notice': '关闭邮箱通知',
  'close mail info': '关闭邮件通知后，您将无法及时收到有关仓位的相关预警提示。',
  'comfir close': '确认关闭',
  considerate: '考虑一下',
  'open info': '开启邮箱通知，及时获取您的仓位信息。',
  'please enter mail': '请输入邮箱账号',
  'unvaild mail': '邮箱格式不正确，请重新输入',
  'open mail success': '您已成功启用邮件通知，请随时查看您的电子邮件。',
  'close mail success': '您已成功取消邮箱通知，平台将不再向您发送与仓位相关的预警提示。',
  next: '下一步',
  'Internal JSON-RPC error.': '内部 JSON-RPC 错误。',
  'Market Order Beyond the slippage price and canceled': '市价订单超过滑点价格，已取消。',
  'The index price is referenced using Chainlink + FastPrice (mainstream CEX prices), and there may be some differences from the actual transaction price.':
    '指数价格仅供参考，以实际成交价格为准。',
  'PNL after fees': '实际损益 = 损益 - 仓位费 - 资金费 - 执行费 - 清算费',
  'Historical Peak': '历史最高',
  'Super Referrers': '超级返佣',
  'Contact us for more discounts': '联系我们获取更多折扣',
  'PNL / ROI': 'PNL / ROI',
  'Target Price': '目标价格',
  'Calculations are for reference only.': '计算结果仅供参考。',
  Calculator: '计算器',
  Calculate: '计算',
  'The initial collateral calculation here does not include any fees or opening losses that need to be deducted.':
    '此处的初始保证金计算未包括任何需要扣除的手续费用或开仓损失。',
  'PNL After Fees': '扣除费后的损益',
  'Unable to reach this return rate.': '无法达到该回报率。',
  'Annual interest rates are for reference only and are subject to weekly updates on Thursdays.':
    '年利率仅供参考，具体以每周四更新为准。',
  'BTC Halving Trading Contest': 'BTC减半交易大赛',
  'Exclusive benefits and rewards for you to unlock !': '专属福利和奖励等你来解锁！',
  'Trading Master': '交易大师',
  'Trading Pioneer': '交易先锋',
  'Your PNL': '您的PNL',
  'Your Trades:': '您的交易次数：',
  'Trading Masters must not have less than 10,000 U as the opening amount in the contest account. Additional margin can be added during liquidation to ensure profitability. The selection criteria during the contest period require Trading Masters to engage in contract trading for no less than 4 days.':
    '交易大师在比赛账户的开仓金额不得低于{value}。 在清算期间可以添加额外的保证金以确保盈利。 在比赛期间的选择标准要求交易大师至少进行{value1}的合约交易。活动期间交易总盈利排名前50即可瓜分此奖池内奖金。',
  'Trading Pioneer accounts must not have less than 500 U as the opening amount. The frequency of trading during the event determines the heat ranking, and those in the top 30 ranks will share the prize pool.':
    '交易先锋账户的开仓金额不得低于{value} 。 活动期间的交易频率决定了热度排名，排名前30名的参与者将分享奖金池。',
  'Prize Distribution Leaderboard：': '奖金分配排行榜：',
  '1. Winners will be awarded an BLEX exclusive Limited edition OG Trader NFT with exclusive benefits!':
    '1. 获胜者将获得独家福利的BLEX专属限量版 {value} ！',
  '2. Participants can compete in both the Trading Masters ranking and the Trading Pioneer heat ranking simultaneously but need to meet the requirements of both categories.':
    '2. 参与者可以同时参加交易大师排名和交易先锋热度排名的比赛，但需要满足两个类别的要求。',
  '3. Blex reserves the right to interpret the event, and the event prizes will be distributed within the second week after the BTC halving.':
    '3. Blex保留解释活动的权利，并且活动奖品将在BTC减半后的第二周内分发。',
  '4 days': '4天',
  '4. Total Prize pool will be determined based on number of contest participants.':
    '4. 总奖池将根据参赛者人数来确定。',
  '1,000 Participants - Prize pool $12,000': '1,000参赛者 - 奖池 $12,000',
  '3,000 Participants - Prize pool $32,000': '3,000参赛者 - 奖池 $32,000',
  '5,000 Participants - Prize pool $60,000': '5,000参赛者 - 奖池 $60,000',
  '10,000 Participants - Prize pool$110,00': '10,000参赛者 - 奖池 $110,000',
  '25,000 Participants - Prize pool $240,000': '25,000参赛者 - 奖池 $240,000',
  '43,000 Participants - Prize pool $420,000': '43,000参赛者 - 奖池 $420,000',
  '5. Users are only eligible to participate with a single wallet address. If we detect multiple wallet addresses with the same IP addresses or UIDs, all offending accounts will be disqualified.':
    '5. 用户只能使用单一钱包地址参与。 如果我们检测到多个钱包地址具有相同的IP地址，所有违规账户将被取消资格。',
  '6. If you invite a friend to join this contest during event period, and this friend ends up on the top 50 leaderboard, then your event PNL will be doubled and your own ranking will likely rise.':
    '6. 在活动期间邀请朋友参加此比赛，如果该朋友进入前50名榜单，您的活动PNL将会加倍，同时您自己的排名也可能上升。',
  "7. This competition will reveal our platform's strongest Top Trader.": '7. 这次比赛将展现我们平台最强的顶级交易员。',
  '8. The following actions will result in immediate disqualification: profits from market manipulation, self-dealing or wash trades.':
    '8. 以下行为将立即导致失格：利用市场操纵、自营或洗单获利。',
  '9. Blex may disqualify participants without explanation.': '9. Blex可能会无需说明而取消参与者资格。',
  '10. Blex reserves the right to cancel, suspend, or postpone the campaign or amend the campaign rules at our sole discretion without notice to you.':
    '10. Blex保留在未通知您的情况下，自行决定取消、暂停、推迟或修改活动规则的权利。',
  '11. Leaderboard rankings do not confer, guarantee, represent or imply eligibility or entitlement for any competitions, giveaways, offers or other contests (“Contest”), or reward from such Contests, during that period. Participation in any contest is subject to applicable rules and eligibility criteria, governed by the terms and conditions specific to that Contest,  and subject to confirmation at the sole and absolute discretion of Blex.':
    '11. 排行榜的排名并不意味着或保证您有资格参加或获得任何在此期间的竞赛、赠品、优惠或其他活动（“竞赛”），或从该等竞赛中获得奖励。参与任何竞赛需遵守适用的规则和资格标准，由该竞赛特定的条款和条件管理，并需经Blex唯一和绝对的裁量确认。',
  '12. Blex reserves the right to change the dates and terms of the Competition at any time without prior announcement.':
    '12. Blex保留在任何时候无需事先通知更改比赛日期和条款的权利。',
}
