import { useEffect } from 'react'

import { useMediaScreen } from '@/lib/hooks/useMedia'

declare const gtag: Function
type EventData = {
  [key: string]: any
}
// Google Analytics 登陆上报
export function usePageView(pageTitle: string, pageLocation: string, pagePath: string) {
  useEffect(() => {
    gtag('config', process.env.GOOGLE_ANALYTICS_KEY, {
      page_title: pageTitle,
      page_location: pageLocation,
      page_path: pagePath,
    })
  }, [pageTitle, pageLocation, pagePath])
}

// Google Analytics 事件上报
export function useEventTracking() {
  const { isMobile } = useMediaScreen()
  const trackEvent = (name: string, eventData: EventData) => {
    // 检查是否定义了 gtag 方法
    if (typeof gtag === 'function') {
      gtag('event', name, {
        equip: isMobile ? 'h5' : 'pc',
        ...eventData,
      })
    } else {
      console.warn('gtag is not defined. Event tracking error.')
    }
  }

  return trackEvent
}
