// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

export async function getRoutes() {
  return {
    routes: {"1":{"path":"/","parentId":"@@/global-layout","id":"1"},"2":{"path":"/close","parentId":"1","id":"2"},"3":{"path":"/","redirect":"/trade","parentId":"1","id":"3"},"4":{"path":"/overview","parentId":"1","id":"4"},"5":{"path":"/home","parentId":"1","id":"5"},"6":{"path":"/trade","parentId":"1","id":"6"},"7":{"path":"/position","parentId":"1","id":"7"},"8":{"path":"/rewards","parentId":"1","id":"8"},"9":{"path":"/earn","parentId":"1","id":"9"},"10":{"path":"/referrals","parentId":"1","id":"10"},"11":{"path":"/more/competitions","parentId":"1","id":"11"},"12":{"path":"/*","parentId":"1","id":"12"},"13":{"path":"/analytics","parentId":"1","id":"13"},"@@/global-layout":{"id":"@@/global-layout","path":"/","isLayout":true}},
    routeComponents: {
'1': React.lazy(() => import(/* webpackChunkName: "components__App__index" */'@/components/App/index.tsx')),
'2': React.lazy(() => import(/* webpackChunkName: "p__trade__ui__ClosePage__index" */'@/pages/trade/ui/ClosePage/index.tsx')),
'3': React.lazy(() => import( './EmptyRoute')),
'4': React.lazy(() => import(/* webpackChunkName: "p__overview__index" */'@/pages/overview/index.tsx')),
'5': React.lazy(() => import(/* webpackChunkName: "p__overview__index" */'@/pages/overview/index.tsx')),
'6': React.lazy(() => import(/* webpackChunkName: "p__trade__index" */'@/pages/trade/index.tsx')),
'7': React.lazy(() => import(/* webpackChunkName: "p__position__index" */'@/pages/position/index.tsx')),
'8': React.lazy(() => import(/* webpackChunkName: "p__rewards__index" */'@/pages/rewards/index.tsx')),
'9': React.lazy(() => import(/* webpackChunkName: "p__rewards__index" */'@/pages/rewards/index.tsx')),
'10': React.lazy(() => import(/* webpackChunkName: "p__referrals__index" */'@/pages/referrals/index.tsx')),
'11': React.lazy(() => import(/* webpackChunkName: "p__competitions__index" */'@/pages/competitions/index.tsx')),
'12': React.lazy(() => import(/* webpackChunkName: "p__404" */'@/pages/404.tsx')),
'13': React.lazy(() => import(/* webpackChunkName: "p__analytics__index" */'@/pages/analytics/index.tsx')),
'@@/global-layout': React.lazy(() => import(/* webpackChunkName: "layouts__index" */'/home/runner/work/depx-view/depx-view/src/layouts/index.tsx')),
},
  };
}
