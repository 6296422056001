import type { MessageDescriptor } from 'react-intl'
import { useIntl } from 'umi'

function useI18n() {
  const intl = useIntl()
  return {
    t: (key: MessageDescriptor | string, values?: Record<string, string>) => {
      if (typeof key == 'string') {
        return intl.formatMessage(
          {
            id: key,
            defaultMessage: values?.defaultMessage || key,
          },
          values,
        )
      }
      return intl.formatMessage(key, values)
    },
  }
}
export default useI18n
