export default {
  'The margin calculation here does not take into account any commission deductions required and opening losses.': '',
  'Please input Leverage': '',
  'Please input Exit Price': '',
  'Please input Amount': '',
  'Please input ROI': '',
  'Please input Entry Price': '',
  'Execution Fee': '',
  'This rate of return cannot be achieved': '',
  'Open Fee': '',
  Indicators: '',
  Result: '',
  'Exit Price': '',
  Tradingview: '',
  'Initial Collateral': '',
  'No Open Positions': '',
  'Confirm Close': '',
  'Are you sure you want to close all positions? lt may require multiple wallet': '',
  'By connecting to the wallet, you agree to {to}. (Last updated on 05/31/2023)': '',
  'BLEX Arbitrum Goerli Testnet': '',
  'Keep Leverage {lev}x': '',
  "BLEX Vault accrues 70% of the platform's generated fees. BLEX Foundation accrues 30% of the platform's generated fees.":
    '',
  'Purchase BLP to provide liquidity and earn 70% of the platform fee distribution.': '',
  'View status': '',
  'Please select a network': '',
  Network: '',
  'Receive test coins': '',
  Language: '',
  connected_to: '',
  Error: '',
  'hide error': '',
  'show error': '',
  'The system is currently busy': '',
  'Transaction sent.': '',
  'Transaction completed!': '',
  'Close All': '',
  status: '',
  'In the trading module, you can open a position by selecting a pair to go long or go short, and setting the amount and quantity you want to buy. After you open a position, you can see all the information about your position in the trading record.':
    '',
  'BLEX {testnet} Testnet': '',
  'Website Pickup': '',
  'New testnet contracts will be deployed this week on the Arbitrum Goerli Testnet, testnet data will be cleared. For testnet users, positions can be closed beforehand if it is desired to retain the testnet funds in the position collateral.':
    '',
  'Edit Referral Code': '',
  'Ends at': '',
  'Starts in': '',
  'Decentralized Perpetual Exchange': '',
  'Please select a language': '',
  USDT: '',
  'Funding Fee / Day:': '',
  'Using Friendly': '',
  'Trading Smooth': '',
  copy: '',
  'Stake BLE and earn Escrowed BLEX rewards and x% of the platform fee distribution.': '',
  'BLE Vault APR': '',
  'Coming Soon': '',
  Telegram: '',
  'in the last 24h': '',
  'Purchase BLP to provide liquidity and earn 100% of the platform fee distribution.': '',
  Referral: '',
  'Dear users, you can execute your trading strategy on BLEX without KYC while controlling of your own assets. Start a silky journey of decentralized perpetual contract trading immediately!':
    '',
  'By binding an invitee, or becoming an invitee, you can get the corresponding rebate bonuses and rebate discounts. The bonus or discount will be automatically paid to your wallet every week.':
    '',
  Staking: '',
  'You can purchase $BLP revenue-added products from the platform, and you can withdraw the proceeds to your wallet at any time after purchase.':
    '',
  Trading: '',
  Connections: '',
  'Select a wallet supported by BLEX by clicking the "Link Wallet" button in the navigation bar. After linking wallets select a network supported by BLEX and enjoy BLEX!':
    '',
  'No Open Orders': '',
  'BLEX T&C': '',
  'No History': '',
  Hide: '',
  'Bind a referral code to get up to 10% trading discount. The trading discount will be returned in USDT every Wednesday.':
    '',
  'Receive Discounts': '',
  'Trading Discount': '',
  'Bind Referral Code': '',
  'Input a referral code to benefit from trade discount.': '',
  'Get discounts on transaction fees when you make a trade.': '',
  'The discounts will be paid out in USDT every Wednesday.': '',
  'Sorry, the page you visited does not exist': '',
  Successful: '',
  'Bridge ETH to Arbitrum': '',
  'There is not enough ETH in your account on Arbitrum to send this transaction.': '',
  'Transaction failed due to RPC error.': '',
  'Please try changing the RPC url in your wallet settings.': '',
  'Transaction failed': '',
  success: '',
  'Share your referral code to receive up to 15% trading rebate. Rebates will be paid out in USDT every Wednesday.': '',
  'Referral Code:': '',
  'Index Perpetual Exchange': '',
  Request: '',
  'Drops are limited to1 request in 8 hours.': '',
  FollowTwitter: '',
  'Wallet Address:': '',
  'Total Balance (USDT)': '',
  'Short Open Interest: {value}': '',
  'Long Open Interest: {value}': '',
  'Open Interest Balance': '',
  'BLEX is a decentralized exchange allowing trading without the need for a username or password. The platform uses an aggregate price feed which reduces the risk of liquidations from temporary wicks.':
    '',
  'How do BLEX Trade work?': '',
  LONG: '',
  SHORT: '',
  'Price Above Liq. Price': '',
  'Price Below Liq. Price': '',
  Slider: '',
  Customize: '',
  'Your wallet is not connected to': '',
  'Sell BLP': '',
  'Buy BLP': '',
  'Selling...': '',
  'Buying...': '',
  'Update Notice': '',
  'Considers settled (closed) positions': '',
  'Fees are not factored into PnL': '',
  Volume: '',
  'Last Month': '',
  'Last 2 Months': '',
  'Last 3 Months': '',
  Users: '',
  'Users Actions': '',
  'Traders Profit vs. Loss': '',
  'Traders Net PnL': '',
  'Total Users': '',
  'BLP Pool': '',
  'Total Fees': '',
  Analytics: '',
  '+$0': '',
  "The BLP Vault is the counterparty to all trades on BLEX and the vault captures 100% of the platform's generated fees.":
    '',
  Status: '',
  'What is BLEX': '',
  earnTip: '',
  'Media Kit': '',
  BugBounty: '',
  'I have understood': '',
  'We recognize that you are trying to access BLEX from a China IP address, please note that per our': '',
  ', we are unable to serve any users from an {regionName} IP.': '',
  'footer.ifYouHaveAnyQuestions': '',
  'footer.products': '',
  'Referral Terms': '',
  'footer.reservedRights': '',
  'Welcome to BLEX': '',
  'wallet.connect': '',
  'wallet.lastUpdated': '',
  'Perpetual Exchange': '',
  'Perpetual Trading': '',
  'By staking, LPs can earn up to 70% of the trading fees and capture the value of the protocol.': '',
  'Earn Now': '',
  'View more': '',
  'Explore The Endless': '',
  'Possibilities With BLEX': '',
  'BLEX is an on-chain decentralized exchange for perpetual futures, ensuring secure funds and transparent data.': '',
  'Smooth Trading': '',
  'Traders can open positions with zero spread using the index price, while the LP vault provides exceptional depth.':
    '',
  'Get Started': '',
  'Trading is available on both the web and mobile app, with easy wallet integration. Joining in only requires one simple step.':
    '',
  'Always at your side': '',
  'Join the BLEX community now!': '',
  Mint: '',
  'We are continuously launching new perpetual contracts markets.': '',
  'Stake BLE and BLP to earn rewards.': '',
  'Get test tokens': '',
  'Wallet Address': '',
  'Share Revenue': '',
  'Txn failed.': '',
  ', we are unable to serve any users from an ': '',
  Switch: '',
  'Connect Wallet': '',
  'Drops are limited to 1 request in 8 hours.': '',
  'Connect your wallet': '',
  Home: '',
  'Bug bounty': '',
  Test: '',
  'Considers Settled (Closed) Positions': '',
  'Fees Are Not Factored Into PnL': '',
  'The total number of positions opened and closed during the event': 'アクティビティ間の累計開倉と平倉合計回数',
  Days: '',
  Hours: '',
  Minutes: '',
  Seconds: '',
  PNL: '',
  'The BLEX bug bounty program is now live! Up to $100,000 in rewards for a key bug report.': '',
  'Assets Under Management': '',
  Service: '',
  'Stake BLE and earn Escrowed BLEX rewards and 30% of the platform fee distribution.': '',
  'Staking to Earn': '',
  'Bind a referral code to get up to 10% trading discount. The trading discount will be returned in USDC every Wednesday.':
    '',
  'Create referral code and start earning rebates!': '',
  'Invite User': '',
  'Share your referral code on social media platforms such as Twitter and Facebook.': '',
  'Earn Rebates': '',
  'When you successfully invite users to trade, the users can enjoy reduced trading fees and you can receive trading rebates.':
    '',
  Referrers: '',
  'Your Active Referral Code': '',
  'Please input a referral code to benefit from fee discounts.': '',
  'Referral code updated failed.': '',
  'Adding referral code failed.': '',
  'Submitting...': '',
  'Checking code...': '',
  Action: '',
  'Creating...': '',
  'Create code failed.': '',
  'Create Referral Code': '',
  'Rebates earned by this account as a trader.': '',
  'Volume traded by this account with an active referral code.': '',
  'You will receive a 5% discount on your opening and closing fees, this discount will be airdropped to your account every Wednesday.':
    '',
  'Total Claimable Reward': '',
  'Claim all': '',
  'Stake BLE and earn Escrowed BLEX rewards and 70% of platform fees distributed.': '',
  'Your Wallet': '',
  'Your Staked': '',
  'Total Staked': '',
  'Buy BLE': '',
  Stake: '',
  Unstake: '',
  'Provide liquidity by purchasing BLP with USDT. and earn 70% of platform fees distributed.': '',
  'BLE Points': '',
  'The fuel for boosting esBLE to BLE.': '',
  'Buy BPT': '',
  'Escrowed BLE': '',
  'Convert esBLE to BLE through Boost and Vest.': '',
  Boost: '',
  Vest: '',
  'Enjoy BLP Rewards': '',
  'Total BLP amount': '',
  'Quantity of NFTs.': '',
  Expand: '',
  'Mint successful': '',
  'Hurry up stake and enjoy the Rewards.': '',
  Done: '',
  'Owning this NFT entitles you to Enjoy BLP Rewards, Double BPT Income!': '',
  'Your Stake': '',
  'Stop loss': '',
  'Take profit': '',
  'Creating Order...': '',
  'Longing...': '',
  'Shorting...': '',
  'Liq. Price': '',
  'View Chart': '',
  Export: '',
  price: '',
  'Net Value': '',
  'Net Value: Initial Collateral + PnL - Funding Fee - Close Fee': '',
  Liq: '',
  'Price below liq. price': '',
  'Price above liq. price': '',
  'Slippage should be less than': '',
  'Slippage should be more than': '',
  'Creating order...': '',
  'Closing...': '',
  'Index Price:': '',
  'Close:': '',
  'Available:': '',
  'Withdrawing...': '',
  'Depositing...': '',
  MAX: '',
  Amount: '',
  'Size:': '',
  'Welcome to BLEX {chainName}. Please pay attention to get the testToken first.': '',
  All: '',
  'Open Long': '',
  'Open Short': '',
  'Close Long': '',
  'Close Short': '',
  Liquidation: '',
  'Partial Liquidation': '',
  'Partial liquidation': '',
  'Withdraw Collateral': '',
  'Deposit Collateral': '',
  '5m': '',
  '15m': '',
  '1h': '',
  '4h': '',
  '1d': '',
  Executed: '',
  Canceled: '',
  Original: '',
  TradingView: '',
  Currency: '',
  'Balance:': 'Solde',
  'The BLP Vault is the counter party to all trades on BLEX.':
    'La BLP Vault est la contrepartie de toutes les transactions sur BLEX',
  'How do BLEX rewards work?': 'Comment fonctionnent les récompenses BLEX?',
  tradesNoHistory: 'Aucun historique pour le moment',
  'How do BLEX Earn work?': 'Comment fonctionnent les récompenses BLEX?',
  'About Blex': 'À propos de BLEX',
  'Connect your wallet to continue': 'Lien portefeuille',
  Filter: 'Filtre',
  'No Record': 'Aucun enregistrement',
  'Go trade now!': 'Échange Instantané!',
  Cancle: 'Annuler',
  'Competitions 🔥': 'Compétitions 🔥',
  Continue: 'Connecter',
  Competitions: 'Compétitions',
  Decentralized: 'Décentralisé',
  'The index price retrieval is incorrect, please try again later.':
    "La récupération du prix de l'indice est incorrecte, veuillez réessayer ultérieurement.",
  'User friendly': "Facile d'utilisation",
  'Event Details>>': "Détails de l'événement >>",
  'BLEX Arbitrum Mainnet Launch on Aug 6th!  Reserve now to share the total $10000 Prize Pool.':
    'Lancement du Mainnet BLEX Arbitrum le 6 août ! Réservez maintenant pour partager le total de la cagnotte de 10000 $.',
  'Congrats! Reservation in Success! Continue to split the final $10000 USDT rewards!':
    'Félicitations ! Réservation réussie ! Continuez à partager la récompense finale de 10000 $ USDT !',
  'Reservation Success': 'Réservation réussie',
  Reserve: 'Réserver',
  'Countdown to the launch': 'Compte à rebours du lancement',
  Connect: '接続',
  'Enter Referral code': 'Entrer un code de parrainage',
  'Open Interest': 'Intérêt ouvert',
  AnalyticsDesc: "Description de l'analyse",
  '24h Change': 'Variation sur 24 h',
  '24h High': '24 h Haut',
  '24h Low': '24 h Bas',
  'Buy / Sell BLP': 'Acheter / Vendre BLP',
  IMakeTrades: "J'ai effectué {value} transaction sur Blex,  ajoutez-moi!",
  'Index Price': "Prix de l'indice",
  'Share Position': 'Partager la position',
  'PnL After Fees:': 'PnL After Fees:',
  'Open Fee {value}': 'Open Fee: {value}',
  'Close Fee {value}': 'Close Fee: {value}',
  'Funding Fee {value}': 'Funding Fee: {value}',
  'PnL {value}': 'PnL: {value}',
  'Initial Collateral {value}': 'Collatéral initial: {value}',
  'Use the edit collateral icon to deposit or withdraw collateral.':
    "Utilisez l'icône Modifier Collatéral pour déposer ou retirer le collatéral.",
  'Funding Fee:': 'Funding Fee:',
  'Collateral:  Initial Collateral - Funding Fee': 'Collateral:  Initial Collateral - Funding Fee',
  'Initial Collateral:': 'Collatéral initial:',
  ConfirmLong: 'Long {symbol}',
  ConfirmShort: 'Short {symbol}',
  Confirm: 'confirmer',
  Networks: 'Réseaux',
  ExecutionFee: 'Execution Fee: {fee}',
  'The execution fee is only charged when the order is executed or canceled due to invalid execution.':
    "Les frais d'exécution ne sont facturés que lorsque l'ordre est exécuté ou annulé en raison d'une exécution non valide.",
  OpenFeeLabel: 'Open Fee: {fee}',
  CloseFeeLabel: 'Close Fee: {fee}',
  FundingFee: 'Funding Fee: {fee}',
  Fees: 'Fees',
  Size: 'Taille',
  Available: 'Disponible',
  MinResidualCollateralUSD: 'Collatéral résiduel minimum : {USD} USD',
  LeftoverNetValue: 'Montant de la valeur nette restante inférieure à {cleanFee} USD',
  LeftoverCollateral: 'Montant du collatéral restant inférieure à {cleanFee} USD',
  AcceptMinimum: 'Accepter le minimum et {action}',
  ExistingIncrease: 'Vous avez déjà plusieurs ordres à cours limité {isLong} {toSymbol} en attente',
  ExistingPosition: "Vous avez déjà une position {isLong} qui augmentera la taille d'une position existante.",
  ConfirmOrderShort: 'Confirmer {toSymbol} pour Short',
  ConfirmOrderLong: 'Confirmer {toSymbol} pour Long',
  'Connected with': 'Connecté avec {value}',
  'View on Explorer': "Voir sur l'explorateur",
  'Txn failed': 'Transaction échouée',
  View: 'Voir',
  text: 'texte',
  'No assets yet？You can bridge ETH from another network, transfer ETH from an exchange':
    "Aucun actif pour le moment,  mais vous pouvez transférer des ETH depuis un autre réseau ou depuis une plateforme d'échange.",
  Homepage: 'Accueil',
  'Trading Volume': 'Volume de trading',
  Open: 'Ouvert',
  Rank: 'Classement',
  Account: 'Compte',
  '': '',
  'Cum.Size': 'Taille cumulée',
  'Cum.Collateral': 'Collatéral cumulé',
  Prize: 'Prix',
  Reward: 'Récompense',
  'Total Profit': 'Profit total',
  'The total volume accumulated between the activity': "Le volume total accumulé pendant l'activité",
  'BLP Supply': 'Offre de BLP',
  'The total BLP supply accumulated between the activity': "L'offre totale de BLP accumulée pendant l'activité",
  'Prize Pool': 'Pool de récompenses',
  'The estimated amount distirbuted to all top traders by competition end results':
    'Le montant estimé distribué aux meilleurs traders en fonction des résultats finaux de la compétition',
  Trades: '取引回数',
  'Rank List': 'Liste des classements',
  'Profit Rank': 'Classement des profits',
  Profit: 'Profit',
  'Trade ROI Rank': 'Classement du rendements des trades',
  'Trade ROI': 'Rendement des trades',
  'Trading Data': 'Données de trading',
  'Total volume': 'Volume total',
  'Fees Paid': 'Frais payés',
  'Trading Fees': 'Frais de trading',
  'Open/Close Fee': "Frais d'ouverture/fermeture",
  'Funding Fee': 'Frais de financement',
  'Read More': 'Lire la suite',
  'Average profit': 'Profit moyen',
  'Average loss': 'Perte moyenne',
  'Address copied to your clipboard': 'Adresse copiée dans votre presse-papiers',
  Traders: 'Traders',
  'Enter referral code': 'Entrer un code de parrainage',
  'Please input a referral code to benefit from fee discounts':
    'Veuillez saisir un code de parrainage pour bénéficier de réductions des frais',
  'Save Changes': 'Enregistrer les modifications',
  'Referral code updated!': 'Code de parrainage mis à jour !',
  'Referral code updated failed': 'Échec de la mise à jour du code de parrainage',
  'Adding referral code failed': "Échec de l'ajout du code de parrainage",
  'Same as current active code': 'Identique au code actif actuel',
  Submitting: 'Soumission en cours',
  'Checking code': 'Vérification du code',
  'Referral code does not exist': "Le code de parrainage n'existe pas",
  'Referral link copied to your clipboard': 'Lien de parrainage a été copié dans votre presse-papiers',
  Creating: 'Création en cours',
  'Enter a code': 'Entrez un code',
  'Code already taken': 'Code déjà utilisé',
  Create: "'Créer",
  title: 'titre',
  desc: 'description',
  'Total Trading Volume': '総取引量',
  'Volume traded by this account with an active referral code':
    'Volume trader par ce compte avec un code de parrainage actif',
  'Total Traders Referred': '推薦された総取引者数',
  'Rebates earned by this account as a trader': 'Les remises gagnées par ce compte en tant que trader',
  'Total Rebates': 'Remises Totaux',
  'Tier 1 (5% rebate)': 'Niveau 1 (Remise de 5%)',
  'Edit Refferal code': 'Modifier le code de parrainage',
  'Active Referral Code': '有効な紹介コード',
  'Tier 1 (5% discount)': 'Niveau 1 (Réduction de 5%)',
  'You will receive a 5% discount on your opening and closing fees, this discount will be airdropped to your account every Wednesday':
    "Vous recevrez une réduction de 5% sur vos frais d'ouverture et de clôture, cette réduction sera distribuée sur votre compte chaque mercredi",
  'Tier 1': 'Niveau 1',
  Rewards: 'Récompenses',
  'Stake USDT and earn rewards from fees generated on the BLEX.':
    'Stakez des USDT et gagnez des récompenses sur les frais générés sur BLEX.',
  'Learn More': 'En savoir plus',
  'Enter an amount': 'Saisissez un montant',
  'Insufficient USDT balance': 'Solde USDT insuffisant',
  'Insufficient BLP balance': 'Solde BLP insuffisant',
  Buy: 'Acheter',
  Sell: 'Vendre',
  'BLP Vault APR': 'APR du Coffre-fort BLP',
  'Your stake': 'Votre stake',
  rewarded: 'récompensé',
  'Pending rewards': 'Récompenses en attente',
  'Harvest USDT': 'Récolter des USDT',
  'Get Insurance': 'Obtenir une assurance',
  Advanced: 'Avancé',
  'Stop Loss': 'Stop Loss',
  'Take Profit': 'Prendre des Bénéfices',
  Slippage: 'Glissement',
  'Note that a low allowed slippage, e.g. less than 0.5%, may result in failed orders if prices are volatile.':
    "Veuillez noter qu'une faible tolérance au glissement autorisé, par exemple inférieure à 0,5 %, peut entraîner des ordres non exécutés en cas de volatilité des prix.",
  'Trading units using stable coins in BLEX such as USD, open position amounts shown are calculated based on the actual coin quantity.Values change with the last price of the symbo':
    "Les unités de trading utilisant des stablecoins sur BLEX tels que l'USD, les montants des positions ouvertes affichés sont calculés en fonction de la quantité réelle du jeton. Les valeurs varient en fonction du dernier prix du symbole.",
  label: 'Etiquette',
  'Entry Price': '',
  'Liq.Price': 'Prix de liquidation',
  Type: 'Type',
  Spread: 'Spread',
  Time: 'Temps',
  Order: 'Ordre',
  Position: 'Position',
  Mark: 'Marquer',
  'Enter a price': 'Entrez un prix',
  'Price above current price': 'Prix supérieur au prix actuel',
  'Price below current price': 'Prix inférieur au prix actuel',
  'Stop loss above current price': 'Stop loss au-dessus du prix actuel',
  'Stop loss below current price': 'Stop loss en dessous du prix actuel',
  'Stop loss below liq.Price': 'Stop loss en dessous du prix de liquidation',
  'Stop loss above liq.Price': 'Stop loss au-dessus du prix de liquidation',
  'Take profit below current price': 'Take profit en dessous du prix actuel',
  'Take profit above current price': 'Take profit au-dessus du prix actuel',
  'Take profit below liq.Price': 'Take profit en dessous du prix de liquidation',
  'Take profit above liq.Price': 'Take profit au-dessus du prix de liquidation',
  'Slippage should be less than %': 'Le glissement doit être inférieur à %',
  'Slippage should be more than %': 'Le glissement doit être supérieur à %',
  Approving: "En cours d'approbation",
  'Incorrect network': 'Réseau incorrect',
  'Create limit order': 'Créer un ordre limite',
  'Available Liquidity': 'Liquidité disponible',
  titleId: 'Titre ID',
  Overview: 'Aperçu',
  More: 'Plus',
  'Your Trades': 'Vos Transactions {trades}',
  'Your Rank': 'Votre classement {tradeROIRank}, Prix {tradeROIRankShow}',
  'Your price': '',
  'Your supply': 'Votre approvisionnement {yourStake}',
  'Your Volume': 'Votre volume {total}',
  AdvancedStopLess:
    "Lorsque le prix de l\\'indice atteint {stopLoss} USD, il déclenchera un , Stop Loss, et le bénéfice net estimé sera de {stopLossPNL} USD.",
  AdvancedProfit:
    "Lorsque le prix de l\\'indice atteint {takeProfit} USD, il déclenchera un , Take Profit, et le bénéfice net estimé sera de {tkpPNL} USD.",
  'Your Rewards': 'Vos récompenses',
  'BLP Vault': 'BLP Vault',
  'Total Supply': 'Quantité Maximale',
  'BLP Vault balance': 'Solde du BLP Vault',
  Entry: 'Entrée',
  MinPayUSD: 'Paiement minimum : {min} USD',
  MaxPayUSD: 'Quantité maximale de la commande : {max} USD',
  MAXLeverage: 'Effet de levier maximal : {max}x',
  MinLeverage: 'Effet de levier minimal : {min}x',
  MAXExceeded: 'Max {toSymbol} {pos} dépassé',
  ConnectWallet: 'Connecter le portefeuille',
  Index: 'Indice',
  Price: 'Prix',
  Disconnect: 'Déconnecter',
  Perpetual: 'Perpétuel',
  Exchange: 'Exchange',
  Copy: 'Copier',
  Download: 'Télécharger',
  Twitter: 'Twitter',
  Share: 'Partager',
  Winrate: 'Taux de réussite',
  Average: '',
  profit: 'profit',
  loss: 'perte',
  Postion: 'Position',
  Net: 'Net',
  Value: 'Valeur',
  Close: 'Fermer',
  Collateral: 'Collatéral',
  Edit: 'Modifier',
  Limit: 'Limite',
  Cancel: 'Annuler',
  Fund: 'Fonds',
  Fee: 'Frais',
  Submit: 'Soumettre',
  fundFeeInfoP1:
    'Le taux de financement est principalement utilisé pour équilibrer le, ratio des positions longues et des positions courtes',
  TermsAndConditions: "Conditions générales d'utilisation",
  MediaKit: 'Kit média',
  Approve: 'Approuver',
  'Connect wallet': 'Connecter le portefeuille',
  'Hide Other Pairs': 'Masquer les autres paires',
  Referrals: 'Parrainages',
  ReferralsSubTitle:
    'Obtenez des réductions de frais et des remises sur les récompenses, grâce au programme de parrainage BLEX.',
  CompetitionsSubtitle:
    'Les compétitions récompenseront les traders ayant le profit total le plus élevé et le plus haut ROI des trades.',
  Governance: 'Gouvernance',
  Community: 'Communauté',
  Developers: 'Développeurs',
  Docs: 'Documentation',
  'Contact us': 'Contactez-nous',
  'Who We Are?': 'Qui sommes-nous ?',
  'Terms of Service': "Conditions générales d'utilisation",
  'Trade BTC, ETH and other top cryptocurrencies with up to 100x leverage directly from your wallet.':
    "Tradez BTC, ETH etd'autres cryptomonnaies populaires avec , un effet de levier allant jusqu'à 100x directement depuis votre portefeuille.",
  'For trader': 'Pour les traders',
  'Using their own wallet to do perpetual trading, with 100x (up to) leverage, 0 spread and excellent depth.':
    "Effectuez des opérations perpétuelles avec votre propre portefeuille, bénéficiez d'un effet de levier allant jusqu'à 100x, d'un spread de 0 et d'une excellente profondeur de marché .",
  Trade: 'Trade',
  'For vault LP': 'Pour les LP du coffre-fort',
  'Earn trading fee with in stable coin staking. No impermanent loss.':
    'Gagnez des frais de trading en misant sur des stablecoins.Aucun impermanent loss.',
  Earn: 'Gagner',
  'Asset Safety': 'Sécurité des actifs',
  'BLEX is a on-chain DEX of perpetual futures. Fund security and data transparency.':
    'BLEX est une plateforme DEX on-chain de contrats à terme perpétuels. Sécurité des fonds et transparence des données.',
  'Avoid IL': 'Éviter les impermanent loss',
  'Following the perpetual futures mechanism, the single currency USD LP vault avoids Impermanent Losses.':
    'Grâce au mécanisme des contrats à terme perpétuels, le coffre-fort en USD à devise unique évite les impermanent loss.',
  'Value Capture': 'Capturer la Valeur',
  'Earn up to 70% trading fee via staking. Capture the value of the protocol.':
    "Gagnez jusqu'à 70 % de frais de trading en misant sur des actifs.Capturez la valeur du protocole.",
  'Trading is available both on WEB and mobile APP. Connecting to the wallet you are using now. Only one step to join in.':
    "Le trading est disponible à la fois sur le WEB et sur l'application mobile. Connectez-vous avec le portefeuille que vous utilisez actuellement. Une seule étape pour rejoindre",
  learnMore: 'En savoir plus',
  'referrals.question': 'Comment fonctionnent les parrainages BLEX ?',
  'referrals.answer1': 'Les remises et les rabais seront distribués en USDT chaque mercredi.',
  'referrals.answer2':
    "Le programme de parrainage offre jusqu'à 10 % de réduction pour, les traders et 15 % de remises au parrain.",
  'referrals.table.date': 'Date',
  'referrals.table.amount': 'MONTANT',
  'referrals.table.transaction': 'Transaction',
  'referrals.table.title': 'Historique de distribution des récompenses',
  'referrals.table.no_history': 'Aucun historique de distribution des récompenses pour le moment.',
  'referrals.looksLike':
    "Il semble que vous n'ayez pas de code de parrainage à distribuer. Entrez un code ci-dessous et appuyez sur soumettre pour le créer sur la chaîne.",
  'referrals.referralCode': 'Code de parrainage',
  'referrals.tradedVolume': 'Volume total',
  'referrals.tradersReferred': 'Traders parrainés',
  'referrals.totalRebates': 'Remises totales',
  referrals: 'Codes de parrainage',
  'referrals.noCodes': 'Aucun code de parrainage pour le moment.',
  'referrals.ttwitterText':
    "Tradez l'ETH/USD et d'autres cryptomonnaies populaires avec un effet de levier allant jusqu'à 100x directement depuis votre portefeuille. @blex.io",
  'referrals.codeLenTip': "'Le code de parrainage ne peut pas dépasser 20 caractères.",
  'referrals.title': 'Entrez le code de parrainage',
  'referrals.subTitle': 'Veuillez saisir un code de parrainage pour bénéficier de la réductions des frais.',
  'referrals.btnText': 'Soumettre',
  'referrals.editBtnText': 'Enregistrer les modifications',
  'referrals.successMsg': 'Code de parrainage mis à jour !',
  'CancelReason.Padding': 'Indéfini',
  'CancelReason.Liquidation': 'Liquidation et annulation automatique.',
  'CancelReason.PositionClosed': 'Position fermée et annulation automatique.',
  'CancelReason.TpAndSlExecuted': 'Take Profit et Stop Loss exécutés et annulation automatique.',
  'CancelReason.SysCancel': "Erreur rencontrée lors de l'exécution du contrat.",
  'CancelReason.PartialLiquidation': 'Liquidation partielle et annulation automatique.',
  above: 'au-dessus',
  below: 'en dessous',
  long: 'long',
  short: 'short',
  tp: 'prise de profit',
  sl: 'stop loss',
  and: 'et',
  OrderType: "Type d'ordre",
  'Orders.noPos': "Aucune position ouverte, l'ordre ne peut être exécuté que si une position est ouverte.",
  'Orders.orderSize':
    "La taille de l'ordre est supérieure à celle de la position, il ne sera, exécutable que si une position est ouverte.",
  'Orders.limitReached':
    "L'ordre déclencheur a atteint la limite supérieure et l'ordre de, stop-loss / take-profit peut ne pas être créé.",
  'Orders.stopLoss': "Stop-loss à {triggerAbove} Liq.Price et l'ordre de stop-loss peut ne pas être créé.",
  'Orders.orderWithType': 'Cet ordre est un ordre {type}.',
  'Orders.orderSet': 'Cet ordre a défini un ordre {TP} {et} {SL}.',
  'Orders.pnlText':
    "Lorsque le prix de l'indice atteint {triggerPrice} USD, cela déclenchera {type}, et le PNL estimé sera de {PNL} USD.",
  'Orders.tpOrSL': 'Take Profit / Stop Loss',
  'Orders.editOrderTitle': "Modifier l'ordre {type}",
  Positions: 'Positions',
  History: 'Historique',
  _Orders: 'Ordres',
  orderPrice: "Prix de l'ordre",
  indexPrice: "Prix de l'indice",
  tpSL: 'TP/SL',
  cancelAll: 'Annuler tout',
  WARNING: 'AVERTISSEMENT',
  liqPrice: 'Prix de liquidation',
  enterPrice: 'Entrer un prix',
  priceAboveCurrent: 'Prix supérieur au prix actuel',
  stopLossAboveOrder: "Stop-loss au-dessus du prix de l'ordre",
  stopLossBelowLiq: 'Stop-loss en dessous du prix de liquidation',
  takeProfitBelowOrder: "Take-profit en dessous du prix de l'ordre",
  priceBelowCurrent: 'Prix inférieur au prix actuel',
  stopLossBelowOrder: "Stop-loss en dessous du prix de l'ordre",
  stopLossAboveLiq: 'Stop-loss au-dessus du prix de liquidation',
  takeProfitAboveOrder: "Take-profit au-dessus du prix de l'ordre",
  priceBelowLiq: 'Prix inférieur au prix de liquidation',
  priceAboveLiq: 'Prix supérieur au prix de liquidation',
  updatingOrder: "Mise à jour de l'ordre...",
  updateOrder: "Mettre à jour l'ordre",
  Pair: 'Paire',
  'position.noOpenPositions': 'Aucune position ouverte',
  'position.sharePosition': 'Partager la position',
  'position.warinitePositions':
    "Cette position dispose d'un faible montant de collatéral après déduction des frais d'emprunt. Déposez davantage de collatéral pour réduire le risque de liquidation de la position.",
  Opening: 'Ouverture…',
  Closing: 'Fermeture…',
  editCollateral: 'Modifier le collatéral',
  entryPrice: "Prix d'entrée",
  netValue: 'Valeur nette',
  Market: 'Marché',
  Trigger: 'Déclencheur',
  welcomeTo: 'Bienvenue sur {type}',
  trustedByUser: 'Plus de {uniqueUsersTotal} traders nous faites confiance.',
  addedLastWeek: "{money} la semaine dernière'",
  tierAndRebate: 'Niveau {level} ({amount}% de remise)',
  tierAndDiscount: 'Niveau {level} ({amount}% de réduction',
  closeIsLongSymbol: 'Close {isLong} {symbol}',
  'Stake USDT and earn rewards from fees generated on the BLEX':
    "Stake de l'USDT et obtenez des récompenses à partir des frais générés sur BLEX",
  'The BLP Vault is the counter party to all trades on BLEX':
    'La BLP Vault est la contrepartie de toutes les transactions sur BLEX',
  "BLEX Vault accrues 70% of the platform's generated fees.BLEX Foundation accrues 30% of the platform's generated fees":
    'La BLEX Vault accumule 70% des frais générés par la plateforme. La BLEX Foundation accumule 30% des frais générés par la plateforme',
  'Hello!': 'Bonjour !',
  '24h Trades': 'Transactions sur 24 heures',
  '24h Volume': 'Volume sur 24 heures',
  'No data': 'Aucune donnée',
  'Up to 20%': "Jusqu'à 20%",
  'Stake USDT and earn rewadrs from fees generated on the BLEX':
    "Stakez de l'USDT et gagnez des récompenses sur les frais générés sur BLEX",
  'Lowest transaction fees': 'Frais de transaction les plus bas',
  'Referral code added!': 'Code de parrainage ajouté !',
  'Create code failed': 'Échec de la création du code',
  'Generate Referral Code': 'Générer un code de parrainage',
  'No rewards distribution history yet': 'Aucun historique de distribution de récompenses pour le moment',
  'Trading units using stable coins in BLEX such as USD, open position amounts shown are calculated based on the actual coin quantity. Values change with the last price of the symbol.':
    "Les unités de trading utilisant des stablecoins tels que l'USD sur BLEX, les montants des positions ouvertes affichés sont calculés en fonction de la quantité réelle du jeton. Les valeurs varient en fonction du dernier prix du symbole.",
  'You have an active trigger order that could impact this Position':
    'Vous avez un ordre déclenché actif qui pourrait avoir un impact sur cette position.',
  'You have multiple active trigger orders that could impact this Position':
    'Vous avez plusieurs ordres déclenchés actifs qui pourraient avoir un impact sur cette position.',
  'You have an active Limit Order that could impact this Position':
    'Vous avez un Limit Order actif qui pourrait avoir un impact sur cette position.',
  'Creating Order': "Création de l'ordre en cours",
  Longing: 'Longing',
  Shorting: 'Shorting',
  'Position Size': 'Taille de Position',
  Leverage: 'Effet de Levier',
  Side: 'Côté',
  Increase: 'Augmenter',
  Long: 'Long',
  Short: 'Short',
  'Price below liq.price': 'Prix inférieur au prix de liquidation',
  'Price above liq.price': 'Prix supérieur au prix de liquidation',
  'Max close amount exceeded': 'Montant maximum de clôture dépassé',
  'Creating order': "Création de l'ordre en cours",
  'Create order': 'Créer un ordre',
  'Keep Leverage': "Conserver l'effet de levier",
  Receive: 'Recevoir',
  Deposit: 'Dépôt',
  Withdraw: 'Retrait',
  'Invalid liq.price': 'Prix de liquidation invalide',
  Withdrawing: 'Retrait en cours',
  Depositing: 'Dépôt en cours',
  Pay: 'Payer',
  'Bug Bounty': 'Bug Bounty',
  'Submit a Bug': 'Submit a Bug',
  Balance: 'Solde',
  'Collateral: Initial Collateral - Funding Fee': 'Collatéral initial: Collatéral initial - Funding Fee',
  'I have made trades on BLEX, come with me!': "J'ai effectué {trades} transaction sur Blex,  ajoutez-moi!",
  '+$0.00': '+$0.00',
  ReferralTerms: 'Conditions de parrainage',
  'Confirm Limit Order': 'Confirmer le Limit Order',
  'Create Order': 'Créer un ordre',
  Prev: '',
  'Share Time': '',
  Next: '',
  'Next Funding Fee': '',
  Countdown: '',
  'Waiting for update': '',
  'The payment rate exchanged between the long and short positions for the next funding. If the funding rate is positive, pay funding fee. If negative, receive funding fee.':
    '',
  'Your network is not good, please reconnect your network': '',
  'Total Volume': '',
  'Top{no}': '',
  'Share {no}': '',
  'Futures Trading Competition': '',
  'Current total trading volume (USDT)': '',
  'Ends in': '',
  'Withdraw collateral': '',
  'Deposit collateral': '',
  'Open Orders': '',
  'Order History': '',
  Bar: '',
  Candle: '',
  Line: '',
  Area: '',
  'Hollow Candle': '',
  Baseline: '',
  'Hi-Lo': '',
  O: '',
  H: '',
  L: '',
  C: '',
  'Event Rules >': 'アクティビティ規則',
  'Competitions will reward traders based on the volume target and rank.':
    '試合は取引量目標と順位に基づいてトレーダーに奨励される。',
  'Trade Now': '即時取引',
  'Current Total Volume': '現在の取引総額',
  'TP/SL Keep Leverage': '',
  Leaderboard: '',
  ROI: '',
  Million: '',
  PositionStatusExecuted: '',
  PositionStatusRequest: '',
  PositionStatusCancelled: '',
  '15-minute waiting period for selling purchased BLP.': '',
  'System is currently upgrading...': '',
  'Copy Link': '',
  'Referral Code copied to your clipboard': '',
  'Total Discount': '合計ディスカウント',
  'Total Rebate': '総リベート',
  'Total Discounts Earned by this Account as a Trader.': 'このアカウントがトレーダーとして得た総割引。',
  'Total Trading Volume with Valid Referral Codes for this Account.':
    'このアカウントが有効な紹介コードで結ばれた総取引量。',
  'Total Rebates Earned by this Account as a Referrer.': 'このアカウントが紹介者として得た総リベート 。',
  'Total Trading Volume of Recommended Traders for this Account.': 'このアカウントの推奨トレーダーの総取引量。',
  'Total Number of Traders Recommended by this Account.': 'このアカウントが推奨したトレーダーの総数。',
  Doc: '',
  Audit: '',
  'Transaction Records': '',
  'Reward Records': '',
  'Total Amount': '',
  'Total Buy Amount': '',
  'Total Sell Amount': '',
  'Transaction was cancelled.': '',
  'Market Order Beyond the slippage price and canceled.': '',
  handCancel: '',
  'System Cancel': '',
  'Error encountered during contract execution(1000).': '',
  Skip: '',
  'Phase II': '二期',
  'Exclusive benefits and rewards for you to unlock, and special rewards for new users!':
    '独自の福利厚生はロック解除を待っており、新しいユーザー専用の奨励金もあります',
  'Will Start In': '開始',
  'So Stay Tuned!': 'お楽しみに',
  'Invite Contest': '招待交易試合',
  'Invite to earn $2,300': '招待勝ち$2300奨励金',
  'Trading Volume Contest': '取引量評価試合',
  'Trade Futures Volume earn $46,500': '取引勝ち$46,500奨励金',
  'Trading ROI Contest': '勝率ランキング戦',
  'Trade Futures ROI to earn $1,200': '収益評価勝ち$1200奨励金',
  'Trading Contest': '取引コンテスト',
  'During the event period, the top 50 future traders will share $46,500 USDT prize pool, based on total trading volume reached on the blex exchange, and their future trading volume.':
    'キャンペーン期間中、公式にはblexプラットフォームの総取引量とユーザー取引量ランキングに基づいて合計$46,500 USDTプールを分割する。具体的なルールは以下の通り：',
  'Detail Rules': '詳細ルール',
  'When Total Trading Volume Reaches': '取引総額が',
  'During the event period, invite your friends to complete a future trading in any value via your invitation code/link. Both the inviting user and the invited user will get $2 USDT. The reward is limited to the first 500 valid invitations, first come first served.':
    '招待者はBLEXが中心化取引所に行って任意の金額の契約取引を完了し、招待コード/招待リンクを通じて友人を招待してBLEXが中心化取引所に行って任意の金額の契約取引を完了することを有効な招待と見なす。有効な招待を完了するたびに、招待者と招待された人はいずれも2 USDT奨励金を得ることができ、奨励金は上位500人の有効な招待に限り、先着順で得ることができる。',
  'Note: The maximum reward for an individual user is $20 USDT.':
    '注意：シングルは最高20 Uの招待奨励金を得ることができます。\n アクティビティが終了し、上位10人のユーザーを効果的に招待し、さらに30 Uのボーナスを受け取ることができます。',
  'Invite Now': '今すぐ招待',
  'ROI Rank': 'ROI（収益率）ランキング',
  'During the event, $1,200 USDT will reward users according to their future trading profitability (ROI) rank. Both the top3 and top 498-500 will get $200 USDT each in this session.':
    '活動期間中、ユーザーはBLEXプラットフォームで倉庫を開けて取引し、取引利益率（ROI）ランキング、活動終了決算、上位3位と498-500のユーザーによって、いずれも200 U奨励金を得ることができる。',
  Notices: '注意',
  '1.Users must meet the requirements during the event to be eligible for the rewards.':
    'ユーザーは活動要件を満たす必要があります。',
  '2.The rewards will be issued within 7 working days after the event.':
    'すべての奨励金は、キャンペーン終了後7営業日以内に支給されます。',
  '3.The trading volume only includes Futures trading.Trading Volume = Opened Positions + Closed Positions.':
    '取引量の計算は契約取引に限られている。取引量=開倉量+平倉量。',
  '4.Users need to accumulate at least 5U to win.': 'ユーザーは少なくとも5 Uを積算する必要があります。',
  '5.No spam is allowed, or you will be disqualified.': '違反ブラシ行為を禁止し、発見されると受賞資格を取り消す。',
  '6.BLEX reserves the right to the final interpretation of this event.': '今回の活動の解釈権はblexの公式所有である。',
  '7.If you have any questions, please contact our admin via':
    '何か問題があれば、DCインボイスアドバイザリに参加してヘルプを求めてください。',
  'Your ROI': 'あなたのROI（収益率）',
  'ROI = (Profit + UnProfit) / Cum.Collateral': '収益率ROI=（損益実現+損益未実現）/累積保証金',
  'Cum.Collateral = Total Opening position + Total Deposit Collateral': '累積保証金＝累積開倉量＋累積充填保証金',
  'Volume = Total Size (Open Size + Close Size)': '取引高=倉庫合計（開梱量+平倉庫量）',
  'Trading Volume = Opened Positions + Closed Positions.': '',
  'discord or telegram': '',
  'In addition, the top 10 inviting users can get an additional $30 USDT reward after the event ends.': '',
  '3.The trading volume only includes Futures trading.': '',
  Top3: '',
  'Bottom 3': '',
  'no list data': '',
  'mail notice': '',
  'mail info Add': '',
  'open mail subscrip': '',
  'got it': '',
  'provide mail account': '',
  'please enter email account': '',
  'error style re enter': '',
  'confirm open': '',
  'close mail notice': '',
  'close mail info': '',
  'comfir close': '',
  considerate: '',
  'open info': '',
  'please enter mail': '',
  'unvaild mail': '',
  'open mail success': '',
  'close mail success': '',
  next: '',
  'Internal JSON-RPC error.': '',
  'Market Order Beyond the slippage price and canceled': '',
  'The index price is referenced using Chainlink + FastPrice (mainstream CEX prices), and there may be some differences from the actual transaction price.':
    '',
  'PNL after fees': '',
  'Historical Peak': '',
  'Super Referrers': '',
  'Contact us for more discounts': '',
  'PNL / ROI': '',
  'Target Price': '',
  'Calculations are for reference only.': '',
  Calculator: '',
  Calculate: '',
  'The initial collateral calculation here does not include any fees or opening losses that need to be deducted.': '',
  'PNL After Fees': '',
  'Unable to reach this return rate.': '',
  'Annual interest rates are for reference only and are subject to weekly updates on Thursdays.': '',
  'BTC Halving Trading Contest': '',
  'Exclusive benefits and rewards for you to unlock !': '',
  'Trading Master': '',
  'Trading Pioneer': '',
  'Your PNL': '',
  'Your Trades:': '',
  'Trading Masters must not have less than 10,000 U as the opening amount in the contest account. Additional margin can be added during liquidation to ensure profitability. The selection criteria during the contest period require Trading Masters to engage in contract trading for no less than 4 days.':
    '',
  'Trading Pioneer accounts must not have less than 500 U as the opening amount. The frequency of trading during the event determines the heat ranking, and those in the top 30 ranks will share the prize pool.':
    '',
  'Prize Distribution Leaderboard：': '',
  '1. Winners will be awarded an BLEX exclusive Limited edition OG Trader NFT with exclusive benefits!': '',
  '2. Participants can compete in both the Trading Masters ranking and the Trading Pioneer heat ranking simultaneously but need to meet the requirements of both categories.':
    '',
  '3. Blex reserves the right to interpret the event, and the event prizes will be distributed within the second week after the BTC halving.':
    '',
  '4 days': '',
  '4. Total Prize pool will be determined based on number of contest participants.': '',
  '1,000 Participants - Prize pool $12,000': '',
  '3,000 Participants - Prize pool $32,000': '',
  '5,000 Participants - Prize pool $60,000': '',
  '10,000 Participants - Prize pool$110,00': '',
  '25,000 Participants - Prize pool $240,000': '',
  '43,000 Participants - Prize pool $420,000': '',
  '5. Users are only eligible to participate with a single wallet address. If we detect multiple wallet addresses with the same IP addresses or UIDs, all offending accounts will be disqualified.':
    '',
  '6. If you invite a friend to join this contest during event period, and this friend ends up on the top 50 leaderboard, then your event PNL will be doubled and your own ranking will likely rise.':
    '',
  "7. This competition will reveal our platform's strongest Top Trader.": '',
  '8. The following actions will result in immediate disqualification: profits from market manipulation, self-dealing or wash trades.':
    '',
  '9. Blex may disqualify participants without explanation.': '',
  '10. Blex reserves the right to cancel, suspend, or postpone the campaign or amend the campaign rules at our sole discretion without notice to you.\n':
    '',
  '11. Leaderboard rankings do not confer, guarantee, represent or imply eligibility or entitlement for any competitions, giveaways, offers or other contests (“Contest”), or reward from such Contests, during that period. Participation in any contest is subject to applicable rules and eligibility criteria, governed by the terms and conditions specific to that Contest,  and subject to confirmation at the sole and absolute discretion of Blex.':
    '',
  '12. Blex reserves the right to change the dates and terms of the Competition at any time without prior announcement.':
    '',
}
