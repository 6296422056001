import cn from 'classnames'
import React from 'react'
import { NavLink } from 'umi'

import { ReactComponent as Arrow1Icon } from '@/assets/arrow1.svg'
import FireGifIcon from '@/assets/fire.gif'

import BubbleDropdown from '@/components/Nav/ui/BubbleDropdown'
import { useEventTracking } from '@/lib/bury'
import useI18n from '@/lib/useI18n'

import s from './index.module.scss'

interface INavProps {
  [key: string]: any
}

interface IMenu {
  path: string
  subPath?: IMenu[]
  title: string
  key: string
  icon?: string
}

const Nav: React.FC<INavProps> = () => {
  const { t } = useI18n()
  const trackEvent = useEventTracking()
  const handleNavLink = (key: string) => {
    trackEvent(`nav_click_${key}`, { event_name: `nav_click_${key}` })
  }
  const menus: IMenu[] = [
    { path: '/home', title: t('Home'), key: 'home' },
    { path: '/trade', title: t('Trade'), key: 'trade' },
    { path: '/earn', title: t('Rewards'), key: 'earn' },
    { path: '/referrals', title: t('Referrals'), key: 'referrals' },
    // { title: t('Competitions'), path: '/more/competitions', key: 'competitions', icon: FireGifIcon },
    { title: t('Competitions'), path: '/more/competitions', key: 'competitions' },

    // {
    //   title: `${t('More')}🔥`,
    //   path: '/more',
    //   key: 'more',
    //   subPath: [
    //     { title: t('Competitions 🔥'), path: '/more/competitions', key: 'competitions' },
    //     { title: t('Analytics'), path: '/analytics', key: 'analytics' },
    //     { title: t('Bug bounty'), path: linksConfig.BugBounty, target: '_blank', key: 'bug_bounty' },
    //     { title: t('Docs'), path: linksConfig.Desc, target: '_blank', key: 'docs' },
    //   ],
    // },
  ]

  return (
    <div className={cn(s.nav, 'fw3')}>
      {menus.map((m) => {
        return m.subPath ? (
          <div className="pr df aic" key={m.path}>
            <BubbleDropdown items={m.subPath}>
              <div className={cn(s.subPathText, 'df aic')}>
                <span className="c90 fw3 cp">{m.title}</span>
                <div className="df aic">
                  <Arrow1Icon />
                </div>
              </div>
            </BubbleDropdown>
          </div>
        ) : (
          <NavLink
            key={m.path}
            to={m.path}
            className={({ isActive }) => {
              const active = isActive || location.pathname.startsWith(m.path) // 支持子路由
              return active ? s.active : undefined
            }}
            onMouseDown={() => handleNavLink(m.key)}
          >
            {m.title}
            {m.icon && <img src={FireGifIcon} alt="fire" className={s.icon} />}
          </NavLink>
        )
      })}
    </div>
  )
}

export default Nav
