export default {
  'The margin calculation here does not take into account any commission deductions required and opening losses.':
    'ここでの初期証拠金の計算には、差し引かれる手数料やオープン時の損失は含まれていません。',
  'Please input Leverage': 'レバレッジを入力してください',
  'Please input Exit Price': 'クローズ価格を入力してください',
  'Please input Amount': '数量を入力してください',
  'Please input ROI': 'ROIを入力してください',
  'Please input Entry Price': '入場価格を入力してください',
  'Execution Fee': '執行手数料',
  'This rate of return cannot be achieved': 'このリターン率に到達できません。',
  'Open Fee': '開設費',
  Indicators: 'インジケーター',
  Result: '結果',
  'Exit Price': '出口価格',
  Tradingview: 'Tradingview',
  'Initial Collateral': '初期保証金',
  'No Open Positions': '保有ポジションはありません',
  'Confirm Close': '平倉を確認する',
  'Are you sure you want to close all positions? lt may require multiple wallet':
    'すべてのポジションを解消しますか? 複数のウォレット取引が必要かもしれません。',
  'By connecting to the wallet, you agree to {to}. (Last updated on 05/31/2023)':
    '「ウォレットに接続することで、{to}に同意します。 （最終更新日：2023年5月31日）」',
  'BLEX Arbitrum Goerli Testnet': 'BLEX Arbitrum Goerli Testnet',
  'Keep Leverage {lev}x': 'レバレッジを効かせる{lev}倍',
  "BLEX Vault accrues 70% of the platform's generated fees. BLEX Foundation accrues 30% of the platform's generated fees.":
    'BLPボールトはBLEX上のすべての取引の対側取引相手であり、そのボールトはプラットフォームで生成されたすべての手数料を100%キャプチャします。',
  'Purchase BLP to provide liquidity and earn 70% of the platform fee distribution.':
    '流動性を提供するためにBLPを購入し、プラットフォーム手数料分配の100％を獲得します。',
  'View status': '状態を見る',
  'Please select a network': 'ネットワークを選択してください',
  Network: 'ネットワーク',
  'Receive test coins': 'テストコインを受け取る',
  Language: '言語',
  connected_to: '{chainName} ネットワークに接続する',
  Error: 'エラー',
  'hide error': 'エラーを隠す',
  'show error': 'エラー表示',
  'The system is currently busy': 'システムは現在ビジー状態です',
  'Transaction sent.': 'リクエストが送信されました。',
  'Transaction completed!': 'リクエストが正常に送信されました。実行をお待ちください。',
  'Close All': 'すべてのポジションをクローズする',
  status: 'ステータス',
  'In the trading module, you can open a position by selecting a pair to go long or go short, and setting the amount and quantity you want to buy. After you open a position, you can see all the information about your position in the trading record.':
    '取引モジュールでは、ペアを選択してロングポジションまたはショートポジションを開き、購入したい数量と金額を設定することができます。ポジションを開くと、取引記録であなたのポジションに関するすべての情報を見ることができます。',
  'BLEX {testnet} Testnet': 'BLEX {testnet} テストネット',
  'Website Pickup': 'ウェブサイトで受け取り',
  'New testnet contracts will be deployed this week on the Arbitrum Goerli Testnet, testnet data will be cleared. For testnet users, positions can be closed beforehand if it is desired to retain the testnet funds in the position collateral.':
    '新しいテストネット契約は今週 Arbitrum Goerli テストネットに展開され、テストネット データは消去されます。 テストネット ユーザーの場合、テストネットの資金をポジション担保に保持しておきたい場合は、ポジションを早期にクローズできます。',
  'Edit Referral Code': '招待コードを編集する',
  'Ends at': 'で終わる',
  'Starts in': 'でスタートした',
  'Decentralized Perpetual Exchange': '分散型永続取引所',
  'Please select a language': '言語を選択してください',
  USDT: 'USDT',
  'Funding Fee / Day:': '資金調達手数料/日:',
  'Using Friendly': 'フレンドリーの利用',
  'Trading Smooth': '取引が順調',
  copy: 'クリップボードにリンクがコピーされました。',
  'Stake BLE and earn Escrowed BLEX rewards and x% of the platform fee distribution.':
    'BLEをステークして、エスクロウされたBLEXリワードとプラットフォーム手数料分配のx％を獲得します。',
  'BLE Vault APR': 'BLEボルトAPR',
  'Coming Soon': '近日公開',
  Telegram: 'Telegram',
  'in the last 24h': '過去24時間で',
  'Purchase BLP to provide liquidity and earn 100% of the platform fee distribution.':
    '流動性を提供するためにBLPを購入し、プラットフォーム手数料分配の100％を獲得します。',
  Referral: '紹介',
  'Dear users, you can execute your trading strategy on BLEX without KYC while controlling of your own assets. Start a silky journey of decentralized perpetual contract trading immediately!':
    '親愛なるユーザーの皆様へ、BLEXではKYCを必要とせずに、ご自身の資産をコントロールしながらトレーディング戦略を実行できます。すぐに分散型永続契約トレーディングのスムーズな旅を始めてください！',
  'By binding an invitee, or becoming an invitee, you can get the corresponding rebate bonuses and rebate discounts. The bonus or discount will be automatically paid to your wallet every week.':
    '招待されたり、招待を受けたりすることで、対応するリベートボーナスやリベート割引を受け取ることができます。ボーナスや割引は毎週自動的にあなたのウォレットに支払われます。',
  Staking: 'ステーキング',
  'You can purchase $BLP revenue-added products from the platform, and you can withdraw the proceeds to your wallet at any time after purchase.':
    'プラットフォームから$BLPの収益付き商品を購入できますし、購入後いつでも収益をウォレットに引き出すことができます。',
  Trading: 'とりひき',
  Connections: 'コネクション',
  'Select a wallet supported by BLEX by clicking the "Link Wallet" button in the navigation bar. After linking wallets select a network supported by BLEX and enjoy BLEX!':
    'BLEXがサポートするウォレットを選択するには、ナビゲーションバーの「ウォレットをリンクする」ボタンをクリックしてください。ウォレットをリンクした後、BLEXがサポートするネットワークを選択して、BLEXをお楽しみください！',
  'No Open Orders': 'オープンオーダーなし',
  'BLEX T&C': 'BLEX利用規約',
  'No History': '当面は記録なし',
  Hide: '隠す',
  'Bind a referral code to get up to 10% trading discount. The trading discount will be returned in USDT every Wednesday.':
    '推奨コードをバインドすると最大 10% の取引割引が得られ、取引割引は毎週水曜日に USDT で返されます。',
  'Receive Discounts': '割引の取得',
  'Trading Discount': '取引割引',
  'Bind Referral Code': '推奨コードをバインドする',
  'Input a referral code to benefit from trade discount.': '推奨コードを入力すると、割引価格で取引ができます。',
  'Get discounts on transaction fees when you make a trade.': '取引時に取引手数料の割引を受けられます。',
  'The discounts will be paid out in USDT every Wednesday.': '取引割引は毎週水曜日にUSDTで返されます。',
  'Sorry, the page you visited does not exist': '申し訳ありませんが、アクセスしたページは存在しません',
  Successful: '成功しました',
  'Bridge ETH to Arbitrum': 'ETHクロスチェーンをArbitrumにブリッジする',
  'There is not enough ETH in your account on Arbitrum to send this transaction.':
    'Arbitrum のアカウントに、このトランザクションを送信するのに十分な ETH がありません。',
  'Transaction failed due to RPC error.': 'RPC エラーによりトランザクションが失敗しました。',
  'Please try changing the RPC url in your wallet settings.': 'ウォレットの設定でRPC URLを変更してみてください。',
  'Transaction failed': '処理に失敗しました',
  success: '成功しました',
  'Share your referral code to receive up to 15% trading rebate. Rebates will be paid out in USDT every Wednesday.':
    '推奨コードを共有すると、取引で最大 15% が戻ってきます。 リベートは毎週水曜日に USDT で返金されます。',
  'Referral Code:': '推奨コード:',
  'Index Perpetual Exchange': '指数永続取引所',
  Request: '申し込み {symbol}',
  'Drops are limited to1 request in 8 hours.': '8時間以内のクレームは1件に限られます。',
  FollowTwitter: '{marketName} の詳細については、Twitter と Telegram をフォローしてください。',
  'Wallet Address:': 'ウォレットアドレス:',
  'Total Balance (USDT)': '総残高（USDT）',
  'Short Open Interest: {value}': 'ショート保有ポジション：{value}',
  'Long Open Interest: {value}': 'ロング保有ポジション：{value}',
  'Open Interest Balance': '保有ポジション残高',
  'BLEX is a decentralized exchange allowing trading without the need for a username or password. The platform uses an aggregate price feed which reduces the risk of liquidations from temporary wicks.':
    'BLEX は、取引にユーザー名やパスワードを必要としない分散型取引所です。 その基礎は、安全、公正、効率的なインデックス価格モデルです。',
  'How do BLEX Trade work?': 'BLEXで取引するにはどうすればよいですか?',
  LONG: 'たくさん作る',
  SHORT: '空売り',
  'Price Above Liq. Price': '価格が清算価格よりも高い',
  'Price Below Liq. Price': '価格が清算価格よりも低い',
  Slider: 'スライダー',
  Customize: 'カスタマイズ',
  'Your wallet is not connected to': 'あなたのウォレットは {chainName} に接続されていません',
  'Sell BLP': 'BLPを売却する',
  'Buy BLP': 'BLPを購入する',
  'Selling...': '売却中..',
  'Buying...': '購入中 ..',
  'Update Notice': 'アップデートのお知らせ',
  'Considers settled (closed) positions': '決済済み（クローズ済み）ポジションを検討する',
  'Fees are not factored into PnL': '費用は損益に含まれません',
  Volume: '取引量',
  'Last Month': '先月',
  'Last 2 Months': '過去2ヶ月',
  'Last 3 Months': '過去3ヶ月',
  Users: 'ユーザー',
  'Users Actions': 'ユーザー操作',
  'Traders Profit vs. Loss': 'トレーダーの損益',
  'Traders Net PnL': 'トレーダーの純損益',
  'Total Users': 'すべてのユーザー',
  'BLP Pool': 'BLP プール',
  'Total Fees': '総費用',
  Analytics: '分析する',
  '+$0': '+$0',
  "The BLP Vault is the counterparty to all trades on BLEX and the vault captures 100% of the platform's generated fees.":
    'BLPボールトはBLEX上のすべての取引の対側取引相手であり、そのボールトはプラットフォームで生成されたすべての手数料を100%キャプチャします。',
  Status: 'ステータス',
  'What is BLEX':
    'BLEXとは」：「BLEXは、分散型のパーペチュアル・エクスチェンジ・プロトコルの確立を目指しています。そのチームメンバーは、トップクラスのCEX、有名なヘッジファンド、ブロックチェーン企業出身者である。BLEXのガバナンスは、BLEX DAOによって決定される。BLEXはシンガポールと香港にオフィスを構え、DAOのメンバーはリモートワークを通じて世界中から貢献しています。BLEXのメンバーとしてあなたをお迎えすることを楽しみにしています。',
  earnTip: '注意：トレーダーとLPの双方に損失リスクがあります。 BLEX契約を行う前に、詳細をよくご確認ください。',
  'Media Kit': 'メディアツール',
  BugBounty: '賞金稼ぎ計画',
  'I have understood': 'わかる',
  'We recognize that you are trying to access BLEX from a China IP address, please note that per our':
    '私たちはあなたが中国のIPアドレスからBLEXにアクセスしようとしていることを発見しました。注意してください、私たちの...',
  ', we are unable to serve any users from an {regionName} IP.':
    '中国のIPからのユーザーにサービスを提供することはできません。',
  'footer.ifYouHaveAnyQuestions':
    '私たちの製品の機能について何か質問があれば、私たちは喜んであなたと話をして、私たちに連絡してください。',
  'footer.products': '製品',
  'Referral Terms': '推奨条項',
  'footer.reservedRights': '© 2023 BLEX著作権所有',
  'Welcome to BLEX': 'BLEXへようこそ',
  'wallet.connect': 'ログインまたはアカウントの作成に接続',
  'wallet.lastUpdated': 'ウォレットに接続すると、BLEX契約に同意することが示されます。（最終更新は2023年5月31日）',
  'Perpetual Exchange': '永続取引所',
  'Perpetual Trading': '永続取引',
  'By staking, LPs can earn up to 70% of the trading fees and capture the value of the protocol.':
    '質権押下により、流動性提供者は最大70%の取引費用を獲得し、合意の価値を得ることができる。',
  'Earn Now': '今すぐ稼ぐ',
  'View more': '詳細を表示',
  'Explore The Endless': '探索',
  'Possibilities With BLEX': 'BLEXの可能性',
  'BLEX is an on-chain decentralized exchange for perpetual futures, ensuring secure funds and transparent data.':
    'BLEXは永続契約チェーン上の中心化取引所であり、資金の安全性、データの透明性を保証する。',
  'Smooth Trading': '取引が順調',
  'Traders can open positions with zero spread using the index price, while the LP vault provides exceptional depth.':
    'トレーダーは指数価格を使用してゼロ点差で倉庫を開けることができ、有限パートナー金庫は卓越した深さを提供する。',
  'Get Started': '使用開始',
  'Trading is available on both the web and mobile app, with easy wallet integration. Joining in only requires one simple step.':
    'トランザクションはネットワークとモバイルアプリケーション上で実行でき、ウォレットを簡単に統合できます。参加するには簡単なステップが必要です。',
  'Always at your side': 'ずっとそばにいる',
  'Join the BLEX community now!': 'BLEXコミュニティに今すぐ参加！',
  Mint: '製造',
  'We are continuously launching new perpetual contracts markets.':
    'デルは継続的に新しい永続契約市場を提供しています。',
  'Stake BLE and BLP to earn rewards.': 'BLEとBLPを質押して奨励を得る。',
  'Get test tokens': 'テストトークンを取得します',
  'Wallet Address': 'ウォレットアドレス',
  'Share Revenue': '収益の共有',
  'Txn failed.': '送信に失敗',
  ', we are unable to serve any users from an ': 'ユーザーにサービスを提供することはできません',
  Switch: 'せんたく',
  'Connect Wallet': 'ウォレットの接続',
  'Drops are limited to 1 request in 8 hours.': '8時間以内に1回限りの受け取りとなります。',
  'Connect your wallet': 'ウォレットの接続',
  Home: 'トップページ',
  'Bug bounty': '賞金プログラム',
  Test: 'テスト',
  'Considers Settled (Closed) Positions': '決済済みを考慮',
  'Fees Are Not Factored Into PnL': '費用は損益に計上しない',
  'The total number of positions opened and closed during the event': 'アクティビティ間の累計開倉と平倉合計回数',
  Days: '天',
  Hours: '時間',
  Minutes: '分',
  Seconds: '秒',
  PNL: 'PNL',
  'The BLEX bug bounty program is now live! Up to $100,000 in rewards for a key bug report.':
    'BLEX賞金プログラムがオンラインになりました！重要な脆弱性を報告することで、最大100,000ドルの奨励金を得ることができます。',
  'Assets Under Management': '資産の管理',
  Service: 'サービス',
  'Stake BLE and earn Escrowed BLEX rewards and 30% of the platform fee distribution.':
    '品質保証BLEは、信託BLE奨励金とプラットフォーム費用収入の30%を稼ぐ。',
  'Staking to Earn': '質権抵当に入れて儲ける',
  'Bind a referral code to get up to 10% trading discount. The trading discount will be returned in USDC every Wednesday.':
    'バインド推奨コードは最大10%の取引割引を受けることができ、取引割引は毎週水曜日にUSDTで返却されます。',
  'Create referral code and start earning rebates!': '推奨コードを作成して利益を得る!',
  'Invite User': '招待ユーザー',
  'Share your referral code on social media platforms such as Twitter and Facebook.':
    'TwitterやFacebookなどのソーシャルメディアプラットフォームでお勧めのコードを共有します。',
  'Earn Rebates': '金をもうけて口銭を返す',
  'When you successfully invite users to trade, the users can enjoy reduced trading fees and you can receive trading rebates.':
    'ユーザーを取引に招待することに成功した場合、ユーザーは取引手数料の割引を受けることができ、取引のリターンを得ることもできます。',
  Referrers: '推薦人',
  'Your Active Referral Code': '有効な推奨コード',
  'Please input a referral code to benefit from fee discounts.': '料金割引を受けるための推奨コードを入力してください。',
  'Referral code updated failed.': '推奨コードの更新に失敗しました',
  'Adding referral code failed.': '推奨コードの追加に失敗しました',
  'Submitting...': 'コミット中',
  'Checking code...': '推奨コードをチェック...',
  Action: 'アクション',
  'Creating...': '作成中..',
  'Create code failed.': '作成に失敗',
  'Create Referral Code': '推奨コードの作成に失敗しました',
  'Rebates earned by this account as a trader.': 'この口座はトレーダーとして得た割引。',
  'Volume traded by this account with an active referral code.': 'このアカウント推奨コードを使用した有効な取引量。',
  'You will receive a 5% discount on your opening and closing fees, this discount will be airdropped to your account every Wednesday.':
    'これはあなたがバインドした紹介者のレベルを示し、またオープン/クローズ手数料に対する受ける割引率を表します。この割引は毎週水曜日にアカウントにエアドロップされます。',
  'Total Claimable Reward': '奨励金を受け取る',
  'Claim all': 'すべて取り出し',
  'Stake BLE and earn Escrowed BLEX rewards and 70% of platform fees distributed.':
    '品質保証BLEは、信託BLE奨励金とプラットフォーム費用収入の30%を稼ぐ。',
  'Your Wallet': 'あなたの財布',
  'Your Staked': 'あなたの抵当',
  'Total Staked': '総質押',
  'Buy BLE': 'BLEの購入',
  Stake: '質押',
  Unstake: '質押を取り消す',
  'Provide liquidity by purchasing BLP with USDT. and earn 70% of platform fees distributed.':
    'USDTでBLPを購入することで流動性を提供し、プラットフォームの費用収入の70%を稼ぐ。',
  'BLE Points': 'BLE積分',
  'The fuel for boosting esBLE to BLE.': 'esBLEをBLEの燃料に変換する。',
  'Buy BPT': 'BPTの購入',
  'Escrowed BLE': '管理対象BLE',
  'Convert esBLE to BLE through Boost and Vest.': 'BoostとVestでesBLEをBLEに変換します。',
  Boost: 'Boost',
  Vest: 'Vest',
  'Enjoy BLP Rewards': 'BLPインセンティブの取得',
  'Total BLP amount': '総BLP数',
  'Quantity of NFTs.': 'NFT数',
  Expand: 'てんかい',
  'Mint successful': '製造に成功',
  'Hurry up stake and enjoy the Rewards.': '早く質押して奨励金をもらおう。',
  Done: '完了',
  'Owning this NFT entitles you to Enjoy BLP Rewards, Double BPT Income!':
    'このNFTを所有することで対応するBLPインセンティブを享受でき、BPT収入の2倍！',
  'Your Stake': 'あなたの抵当',
  'Stop loss': 'ストップロス',
  'Take profit': 'テイクプロフィット',
  'Creating Order...': 'オーダーの作成中...',
  'Longing...': '多中...',
  'Shorting...': '空中...',
  'Liq. Price': '清算価額',
  'View Chart': '表の表示',
  Export: 'エクスポート',
  price: '価格',
  'Net Value': '純資産',
  'Net Value: Initial Collateral + PnL - Funding Fee - Close Fee':
    '純資産：初期デポジット+損益-資金費-ポジションクローズ費',
  Liq: '清算',
  'Price below liq. price': '価格が清算価格を低い',
  'Price above liq. price': '価格が清算価格より高い',
  'Slippage should be less than': 'スライディングポイントはより小さい必要があります',
  'Slippage should be more than': 'スライドポイントはより大きい必要があります',
  'Creating order...': 'オーダーの作成中...',
  'Closing...': 'ポジションクローズ中...',
  'Index Price:': '指数価格：',
  'Close:': 'ポジションクローズ：',
  'Available:': '使用可能：',
  'Withdrawing...': '取り出し中...',
  'Depositing...': 'チャージ中...',
  MAX: '最大数',
  Amount: '数',
  'Size:': 'ポジション：',
  'Welcome to BLEX {chainName}. Please pay attention to get the testToken first.':
    'BLEX {chainName} へようこそ。まずテストトークンを受け取ってください。',
  All: 'フル',
  'Open Long': 'ロング',
  'Open Short': 'ショート',
  'Close Long': 'フラット ロング',
  'Close Short': 'フラット ショート',
  Liquidation: '清算',
  'Partial Liquidation': '部分清算',
  'Partial liquidation': '部分清算',
  'Withdraw Collateral': '証拠金引き出し',
  'Deposit Collateral': '証拠金預託',
  '5m': '5分',
  '15m': '15分',
  '1h': '1時間',
  '4h': '4時間',
  '1d': '1日',
  Executed: '実行しました',
  Canceled: 'キャンセルしました',
  Original: '基本バージョン',
  TradingView: 'TradingView',
  Currency: '通貨タイプ',
  'Balance:': '残高:',
  'The BLP Vault is the counter party to all trades on BLEX.': 'BLEX上のすべての取引の相手方はBLP Vaultです。',
  'How do BLEX rewards work?': 'BLEXの報酬はどうなっているのか？',
  tradesNoHistory: '履歴がない',
  'How do BLEX Earn work?': 'BLEXの報酬はどうなっているのか？',
  'About Blex': 'BLEXについて',
  'Connect your wallet to continue': 'ウォレットの接続',
  Filter: 'フィルター',
  'No Record': '記録がない',
  'Go trade now!': '即時取引!',
  Cancle: 'キャンセル',
  'Competitions 🔥': 'コンペティション',
  Continue: '続行',
  Competitions: 'コンペティション',
  Decentralized: '分散型',
  'The index price retrieval is incorrect, please try again later.':
    'インデックス価格の取得が正しくありません、後でもう一度お試しください。',
  'User friendly': 'フレンドリーの利用',
  'Event Details>>': 'アクティビティの詳細>>',
  'BLEX Arbitrum Mainnet Launch on Aug 6th!  Reserve now to share the total $10000 Prize Pool.':
    'BLEX Arbitrumメインネットワークは8月6日にオンラインになります！すぐに予約すれば、合計10,000ドルのプールを共有できます。',
  'Congrats! Reservation in Success! Continue to split the final $10000 USDT rewards!':
    'おめでとう！予約成功！最終的な10,000ドルUSDTインセンティブを分割し続けます！',
  'Reservation Success': '予約成功',
  Reserve: '予約する',
  'Countdown to the launch': 'カウントダウン開始',
  Connect: '接続',
  'Enter Referral code': '推奨コードを入力',
  'Open Interest': '保有ポジション',
  AnalyticsDesc: '{time}、{block} ブロックに更新されました',
  '24h Change': '24時間変更',
  '24h High': '24時間最高値',
  '24h Low': '24時間最低価格',
  'Buy / Sell BLP': 'BLPを売却する',
  IMakeTrades: 'BLEX で {value} 件の取引を完了しました。ぜひ参加してください!',
  'Index Price': '指数価格',
  'Share Position': 'ポジションシェア',
  'PnL After Fees:': '手数料差し引き後の損益：',
  'Open Fee {value}': '開設費：{value}',
  'Close Fee {value}': '保管料：{value}',
  'Funding Fee {value}': '資金調達手数料：{value}',
  'PnL {value}': '損益：{value}',
  'Initial Collateral {value}': '初期保証金：{value}',
  'Use the edit collateral icon to deposit or withdraw collateral.':
    '保証金の入金または出金には、保証金の編集アイコンを使用します。',
  'Funding Fee:': '資金調達手数料:',
  'Collateral:  Initial Collateral - Funding Fee': '保証金: 初期保証金 - 資金調達手数料',
  'Initial Collateral:': '初期保証金：',
  ConfirmLong: 'たくさん作る {symbol}',
  ConfirmShort: '空売り {symbol}',
  Confirm: '確認',
  Networks: 'ネットワーク',
  ExecutionFee: '執行手数料：{fee}',
  'The execution fee is only charged when the order is executed or canceled due to invalid execution.':
    '約定手数料は、注文が約定された場合、または無効な約定によりキャンセルされた場合にのみ請求されます。',
  OpenFeeLabel: '開設費：{fee}',
  CloseFeeLabel: '保管料：{fee}',
  FundingFee: '資金調達手数料：{fee}',
  Fees: '料金について',
  Size: 'サイズ',
  Available: '利用可能',
  MinResidualCollateralUSD: '最低残存担保{USD}となります',
  LeftoverNetValue: '残りの純資産が ${cleanFee} 未満',
  LeftoverCollateral: '${cleanFee} 未満の担保物',
  AcceptMinimum: '最小値を受け入れて{action}',
  ExistingIncrease: '既存の{isLong} {toSymbol}指値注文が複数あります',
  ExistingPosition: 'すでに{isLong}のポジションを持っているので、既存のポジションのサイズを大きくします。',
  ConfirmOrderShort: '{toSymbol}のショートオーダーを確認する',
  ConfirmOrderLong: '{toSymbol}のロングオーダーを確認する',
  'Connected with': '接続する {value}',
  'View on Explorer': 'ブロックチェーンブラウザで見る',
  'Txn failed': 'トランザクションに失敗しました',
  View: '検索',
  text: 'テキスト',
  'No assets yet？You can bridge ETH from another network, transfer ETH from an exchange':
    'まだ資産がありませんか？別のネットワークからETHをブリッジしたり、取引所からETHを転送したりできます。',
  Homepage: 'ホームページ',
  'Trading Volume': '取引量',
  Open: 'オーペンポジション',
  Rank: 'ランク',
  Account: 'アカウント',
  '': '',
  'Cum.Size': '累積数',
  'Cum.Collateral': '累積担保物件',
  Prize: '賞金',
  Reward: '報酬',
  'Total Profit': '総利益',
  'The total volume accumulated between the activity': 'キャンペーン期間中の累積取引量。',
  'BLP Supply': 'BLP供給量',
  'The total BLP supply accumulated between the activity': 'キャンペーン期間中のBLPの累積供給量',
  'Prize Pool': 'ボーナスプール',
  'The estimated amount distirbuted to all top traders by competition end results':
    '大会終了後、すべてのトップトレーダーに分配される予定額。',
  Trades: '取引回数',
  'Rank List': 'ランキング',
  'Profit Rank': '利益ランキング',
  Profit: '利益',
  'Trade ROI Rank': '取引ROIランキング',
  'Trade ROI': '取引ROI',
  'Trading Data': '取引データ',
  'Total volume': '総取引高',
  'Fees Paid': '支払い済み手数料',
  'Trading Fees': '取引手数料',
  'Open/Close Fee': 'オーペンポジション/デイトレーダー手数料',
  'Funding Fee': '資金調達費用',
  'Read More': 'もっと見る',
  'Average profit': '平均利益',
  'Average loss': '平均損失額',
  'Address copied to your clipboard': 'アドレスがクリップボードにコピーされました',
  Traders: 'トレーダー',
  'Enter referral code': '推奨コードを入力',
  'Please input a referral code to benefit from fee discounts':
    '料金割引の特典を受けるには、推奨コードを入力してください',
  'Save Changes': '変更を保存する',
  'Referral code updated!': '推奨コードが更新されました！',
  'Referral code updated failed': '推奨コードの更新に失敗しました',
  'Adding referral code failed': '推奨コードの追加に失敗しました',
  'Same as current active code': '現在のアクティブコードと同じ',
  Submitting: '提出中',
  'Checking code': 'コードをチェックしています',
  'Referral code does not exist': '推奨コードが存在しない',
  'Referral link copied to your clipboard': '紹介リンクがクリップボードにコピーされました。',
  Creating: '作成中',
  'Enter a code': 'コードを入力する',
  'Code already taken': '使用済みコードです',
  Create: '作成',
  title: 'タイトル',
  desc: 'ディスクリプタ',
  'Total Trading Volume': '総取引量',
  'Volume traded by this account with an active referral code': 'イベント推奨コードによる該当口座の総取引数',
  'Total Traders Referred': '推薦された総取引者数',
  'Rebates earned by this account as a trader': '本アカウントがトレーダーとして獲得したリベート',
  'Total Rebates': 'トータルリベート',
  'Tier 1 (5% rebate)': 'ファーストティア（5％割引）',
  'Edit Refferal code': '推奨コードを編集する',
  'Active Referral Code': '有効な紹介コード',
  'Tier 1 (5% discount)': 'レベル1（5％割引）',
  'You will receive a 5% discount on your opening and closing fees, this discount will be airdropped to your account every Wednesday':
    '毎週水曜日に口座に入金されるオーペンポジション・デイトレーダー手数料が5％割引になります',
  'Tier 1': 'レベル1',
  Rewards: 'リワード',
  'Stake USDT and earn rewards from fees generated on the BLEX.':
    'USDTを质入れ、BLEXが負担した料金から賞金を受け取る。',
  'Learn More': 'もっと詳しく',
  'Enter an amount': '数量を入力する',
  'Insufficient USDT balance': 'USDT残高不足',
  'Insufficient BLP balance': 'BLP残高不足',
  Buy: '買う',
  Sell: '売り',
  'BLP Vault APR': 'BLP Vault年率換算リターン',
  'Your stake': 'あなたのステーク',
  rewarded: 'リワード',
  'Pending rewards': '請求できる報酬',
  'Harvest USDT': 'ハーベストUSDT',
  'Get Insurance': '保険へのアクセス',
  Advanced: '詳細オプション',
  'Stop Loss': 'ストップロス',
  'Take Profit': 'テイクプロフィット',
  Slippage: 'スリッページ',
  'Note that a low allowed slippage, e.g. less than 0.5%, may result in failed orders if prices are volatile.':
    '許容スリッページが低い（例えば0.5％以下）場合、価格が変動した際に注文が失敗することがありますのでご注意ください。',
  'Trading units using stable coins in BLEX such as USD, open position amounts shown are calculated based on the actual coin quantity.Values change with the last price of the symbo':
    'BLEXでUSDのような安定したコインで取引する場合、表示されるポジションは実際のコイン数に基づいて計算されます。価値はシンボルの最終価格によって変化します。',
  label: 'ラベル',
  'Entry Price': 'エントリー価格',
  'Liq.Price': '強制決済価格',
  Type: 'タイプ',
  Spread: 'スプレッド',
  Time: 'タイム',
  Order: 'オーダー',
  Position: 'ポジション',
  Mark: 'マーク',
  'Enter a price': '価格を入力する',
  'Price above current price': '現在の価格を上回る',
  'Price below current price': '現在の価格を下回る',
  'Stop loss above current price': 'ストップロスを現在の価格より上にする',
  'Stop loss below current price': 'ストップロスが現在の価格より低い',
  'Stop loss below liq.Price': 'ストップロスが現在の価格より低い',
  'Stop loss above liq.Price': 'ストップロスは強制決済価格より高い',
  'Take profit below current price': 'テイクプロフィットが現在の価格より低い',
  'Take profit above current price': 'テイクプロフィットを現在の価格より高い',
  'Take profit below liq.Price': 'テイクプロフィットが強制決済価格より低い',
  'Take profit above liq.Price': 'テイクプロフィットが強制決済価格より高い',
  'Slippage should be less than %': 'スリッページは％以下でなければならない',
  'Slippage should be more than %': 'スリップポイントは%より大きい必要があります',
  Approving: '承認中',
  'Incorrect network': 'ネットワークエラー',
  'Create limit order': '指値注文を作成する',
  'Available Liquidity': '利用可能な流動性',
  titleId: 'タイトルID',
  Overview: 'トップページ',
  More: 'もっと',
  'Your Trades': 'あなたの取引 {trades}',
  'Your Rank': 'あなたのランキング{tradeROIRank}',
  'Your price': '賞金: {tradeROIRankShow}',
  'Your supply': 'あなたの供給{yourStake}',
  'Your Volume': 'あなたのボリューム{total}',
  AdvancedStopLess:
    'インデックス価格が{stopLoss} USDに達すると、ストップロスで出発し、推定PnLは{stopLossPNL} USDになります。',
  AdvancedProfit:
    'インデックス価格が{takeProfit} USDに達すると、テイクプロフィットを取り、推定PnLは{takeProfit} USDになります。',
  'Your Rewards': 'あなたの報酬',
  'BLP Vault': 'BLP Vault',
  'Total Supply': 'トータルサプライ',
  'BLP Vault balance': 'BLP Vaultのバランス',
  Entry: 'エントリー',
  MinPayUSD: '最低支払額：{min} USD',
  MaxPayUSD: '最大注文サイズ：{max} USD',
  MAXLeverage: 'マックスレバレッジ：{max}x',
  MinLeverage: 'ミックスレバレッジ：{min}x',
  MAXExceeded: 'オーバーマックス {toSymbol} {pos}',
  ConnectWallet: 'コネクトウォレット',
  Index: 'インデックス',
  Price: '価格',
  Disconnect: 'ディスコネクト',
  Perpetual: '永久契約',
  Exchange: '取引所',
  Copy: 'コピー',
  Download: 'ダウンロード',
  Twitter: 'ツイッター',
  Share: 'シェア',
  Winrate: '勝率',
  Average: '平均',
  profit: 'プロフィット',
  loss: 'ロス',
  Postion: 'ポジション',
  Net: 'ネット',
  Value: 'バリュー',
  Close: 'クローズ',
  Collateral: 'コラテラル',
  Edit: '編集',
  Limit: 'リミット',
  Cancel: 'キャンセル',
  Fund: '資金',
  Fee: '取扱手数料',
  Submit: '投稿',
  fundFeeInfoP1:
    'ファンディングレートは主にロングポジションとショートポジションの比率のバランスを取るために使用される。',
  TermsAndConditions: '利用規約',
  MediaKit: 'メディアリキット',
  Approve: '承認',
  'Connect wallet': 'コネクテッドウォレット',
  'Hide Other Pairs': '他の取引ペアを隠す',
  Referrals: 'リファラル',
  ReferralsSubTitle: 'BLEX紹介プログラムによる料金割引やインセンティブリベートを受けることができる。',
  CompetitionsSubtitle: 'このコンペティションでは、総利益と取引ROIが最も高いトレーダーを表彰します。',
  Governance: 'ガバナンス',
  Community: 'コミュニティ',
  Developers: '開発者',
  Docs: 'ドックス',
  'Contact us': 'お問い合わせ',
  'Who We Are?': '私たちは何者か',
  'Terms of Service': 'サービス利用規約',
  'Trade BTC, ETH and other top cryptocurrencies with up to 100x leverage directly from your wallet.':
    'BTC、ETH、その他の人気のある暗号通貨を、あなたのウォレットから直接、最大100倍のレバレッジで取引する。',
  'For trader': 'トレーダー向け',
  'Using their own wallet to do perpetual trading, with 100x (up to) leverage, 0 spread and excellent depth.':
    'レバレッジ100倍、スリッページ0、取引深度抜群の取引所で、自分の財布でデリバティブを取引する！',
  Trade: 'トレード',
  'For vault LP': '引当金LPのため',
  'Earn trading fee with in stable coin staking. No impermanent loss.':
    '変動損失がなく、安定したコインの差し入れで取引手数料を稼ぐ。',
  Earn: '儲ける',
  'Asset Safety': '資産の安全性',
  'BLEX is a on-chain DEX of perpetual futures. Fund security and data transparency.':
    'BLEXはパーペチュアルフューチャーのオンチェーンDEXです。ファンドのセキュリティとデータの透明性。',
  'Avoid IL': '変動損失を回避する',
  'Following the perpetual futures mechanism, the single currency USD LP vault avoids Impermanent Losses.':
    'パーペチュアルフューチャーメカニズムに従い、単一通貨USD LPリザーブは変動損失を回避する。',
  'Value Capture': 'バリューキャプチャー',
  'Earn up to 70% trading fee via staking. Capture the value of the protocol.':
    'ステーキングで最大70%の取引手数料を稼ぐ。プロトコルの価値を捕捉する契約の価値をとらえる。',
  'Trading is available both on WEB and mobile APP. Connecting to the wallet you are using now. Only one step to join in.':
    'WEBとモバイルAPPの両方でお取引が可能です。使用しているウォレットを接続する。たったワンステップで参加できる。',
  learnMore: '詳細はこちら',
  'referrals.question': 'BLEXの紹介プログラムの仕組みについて？',
  'referrals.answer1': '割引やリベートはUSDTという形で毎週配布される。',
  'referrals.answer2': '紹介制度により、トレーダーは最大10％の割引、紹介者は15％のリベートを受けられる。',
  'referrals.table.date': 'デイトス',
  'referrals.table.amount': '数量',
  'referrals.table.transaction': 'トランザクション',
  'referrals.table.title': '報酬の配分履歴',
  'referrals.table.no_history': '報酬配分履歴はまだない」。',
  'referrals.looksLike':
    '推奨コードを持っていないようです。 下のコードを入力し、送信をクリックすると、チェーン上で作成されます。',
  'referrals.referralCode': 'リファラルプログラム リファーラルコード',
  'referrals.tradedVolume': 'リファラル・プログラム 総取引量',
  'referrals.tradersReferred': 'リファラルプログラム 紹介されたトレーダーの',
  'referrals.totalRebates': 'リファーラル・プログラム トータルリベート',
  referrals: 'リファラルプログラム リファーラルコード',
  'referrals.noCodes': '推奨コードはまだありません。',
  'referrals.ttwitterText':
    'ETH/USDやその他のトップクラスの暗号通貨を、あなたのウォレットで直接、最大100倍のレバレッジで取引しましょう。 @blex.io',
  'referrals.codeLenTip': '推奨コードは20文字を超えてはいけません',
  'referrals.title': '推奨コードを入力する',
  'referrals.subTitle': '手数料の減額を受けるために推奨コードを入力してください」。',
  'referrals.btnText': '投稿',
  'referrals.editBtnText': '変更を保存する',
  'referrals.successMsg': '推奨コードが更新されました！',
  'CancelReason.Padding': '未定義',
  'CancelReason.Liquidation': '強制決済及び自動的にキャンセルされる',
  'CancelReason.PositionClosed': 'ポジションがクローズされ、自動的にキャンセルされる',
  'CancelReason.TpAndSlExecuted': 'テイクプロフィット、ストップロスが実行され、自動的にキャンセルされる',
  'CancelReason.SysCancel': '契約実行中にエラーが発生しました',
  'CancelReason.PartialLiquidation': '一部強制決済とポジションの自動キャンセル',
  above: 'それ以上',
  below: 'それ以下',
  long: 'ロング',
  short: 'ショート',
  tp: 'テイクプロフィット',
  sl: 'ストップロス',
  and: 'と',
  OrderType: '注文タイプ',
  'Orders.noPos': '保有ポジションがない、注文を出す前にオープンする必要がある。',
  'Orders.orderSize': '注文はポジションサイズより大きく、ポジションが存在する場合にのみ発注できる。',
  'Orders.limitReached': 'トリガー注文が限界に達し、ストップロス/ストップゲイン注文が作成されない。',
  'Orders.stopLoss': 'ストップロス価格{triggerAbove} 証拠金不足価格、ストップロス注文は作成されません。',
  'Orders.orderWithType': 'この注文は {type} の注文です。',
  'Orders.orderSet': 'この注文は{TP}{and}{SL}の注文として設定されている。',
  'Orders.pnlText':
    'インデックス価格が{triggerPrice} $に達すると{type}注文がトリガーされ、予想利益/損失は{PNL} $となる。',
  'Orders.tpOrSL': 'テイクプロフィット/ストップロス',
  'Orders.editOrderTitle': '{type} の注文を編集する',
  Positions: 'ポジション',
  History: '履歴',
  _Orders: 'オーダー',
  orderPrice: 'オーダー価格',
  indexPrice: 'インデックスプライス',
  tpSL: 'テイクプロフィット、ストップロス」',
  cancelAll: 'すべてをキャンセルする',
  WARNING: '警告',
  liqPrice: '強制決済価格',
  enterPrice: '価格を入力する',
  priceAboveCurrent: '現在の価格より高い',
  stopLossAboveOrder: 'ストップロスが注文価格より高い',
  stopLossBelowLiq: 'ストップロスが証拠金不足価格より低い',
  takeProfitBelowOrder: 'テイクプロフィットが注文価格より低い',
  priceBelowCurrent: '現在の価格より低い',
  stopLossBelowOrder: 'ストップロスが注文価格より低い',
  stopLossAboveLiq: 'ストップロスは強制決済価格より高い',
  takeProfitAboveOrder: 'テイクプロフィットが注文価格より高い',
  priceBelowLiq: '証拠金不足価格より低い',
  priceAboveLiq: '証拠金不足価格より高い',
  updatingOrder: 'オーダー更新中...',
  updateOrder: '注文を更新する',
  Pair: 'トレーディングペア',
  'position.noOpenPositions': '保有ポジションはありません',
  'position.sharePosition': 'ポジションを共有する',
  'position.warinitePositions':
    'このポジションは借入費用控除後の保証金が少なく、ポジションの清算リスクを軽減するために、より多くの保証金を預ける。',
  Opening: 'オープニングポジションを進行中...',
  Closing: 'ポジションクローズ中...',
  editCollateral: '担保の変更',
  entryPrice: '初値',
  netValue: 'ネットバリュー',
  Market: 'マーケット',
  Trigger: 'トリガー価格',
  welcomeTo: 'ようこそ{type}へ。',
  trustedByUser: '{uniqueUsersTotal}名のトレーダーから信頼されている。',
  addedLastWeek: '先週追加され：{money}',
  tierAndRebate: 'レベル {level}({amount}% ベート)',
  tierAndDiscount: 'レベル {level}({amount}% 割引)',
  closeIsLongSymbol: 'クローズ {isLong} {symbol}',
  'Stake USDT and earn rewards from fees generated on the BLEX': 'USDTを賭けて、BLEXで発生する手数料から報酬を得る',
  'The BLP Vault is the counter party to all trades on BLEX': 'BLEX上のすべての取引の相手方はBLP Vaultです',
  "BLEX Vault accrues 70% of the platform's generated fees.BLEX Foundation accrues 30% of the platform's generated fees":
    'BLEX Vaultはプラットフォームの手数料の70％を受け取り、BLEX Foundationはプラットフォームの手数料の30％を受け取る。',
  'Hello!': 'こんにちは',
  '24h Trades': '24時間取引',
  '24h Volume': '24時間取引量',
  'No data': 'データがない',
  'Up to 20%': '最大20％',
  'Stake USDT and earn rewadrs from fees generated on the BLEX':
    'USDTを誓約して、BLEXで発生する手数料からボーナスを受け取る」。',
  'Lowest transaction fees': '最低取引手数料',
  'Referral code added!': '推奨コードが追加されました',
  'Create code failed': '推奨コードの作成に失敗しました',
  'Generate Referral Code': '推奨コードを生成する',
  'No rewards distribution history yet': '現時点では賞付与の履歴がない',
  'Trading units using stable coins in BLEX such as USD, open position amounts shown are calculated based on the actual coin quantity. Values change with the last price of the symbol.':
    'BLEXで安定コイン（USDなど）を使って取引する場合、表示されるポジション額は実際のコイン数に基づいて計算されます。 この値は、取引ペアの最新価格によって変化します。',
  'You have an active trigger order that could impact this Position':
    'このポジションに影響を与える可能性のあるトリガーオーダーが発動している',
  'You have multiple active trigger orders that could impact this Position':
    'このポジションに影響を与える可能性のあるトリガーオーダーが複数発動していますね」',
  'You have an active Limit Order that could impact this Position':
    'この位置に影響を与える可能性のあるリミットオーダーが有効になっています',
  'Creating Order': 'オーダーを作成している',
  Longing: 'ロング',
  Shorting: 'ショート',
  'Position Size': 'ポジションサイズ',
  Leverage: 'レバレッジ',
  Side: 'サイド',
  Increase: '増やす',
  Long: 'ロング',
  Short: 'ショート',
  'Price below liq.price': '清算価格より低い',
  'Price above liq.price': '清算価格より高い',
  'Max close amount exceeded': '最大クローズ量を超えています',
  'Creating order': 'オーダーを作成しています',
  'Create order': '注文の作成',
  'Keep Leverage': 'レバレッジを効かせる',
  Receive: '受取る',
  Deposit: '預金',
  Withdraw: '出金',
  'Invalid liq.price': '無効な清算価格',
  Withdrawing: '引き出す中',
  Depositing: '貯金中',
  Pay: '支払う',
  'Bug Bounty': '賞金稼ぎ計画',
  'Submit a Bug': '抜け穴を提出する',
  Balance: '残高',
  'Collateral: Initial Collateral - Funding Fee': '担保: 最初の担保 - Funding Fee',
  'I have made trades on BLEX, come with me!': 'BLEXで {trades} 回取引しました、一緒に来てください！',
  '+$0.00': '+$0.00',
  ReferralTerms: '紹介条件',
  'Confirm Limit Order': '価格制限オーダーの確認',
  'Create Order': 'オーダーを作成する',
  Prev: '前へ',
  'Share Time': '時間を共有する',
  Next: '次へ',
  'Next Funding Fee': '次回資金調達手数料',
  Countdown: '秒読み',
  'Waiting for update': '更新を待っています',
  'The payment rate exchanged between the long and short positions for the next funding. If the funding rate is positive, pay funding fee. If negative, receive funding fee.':
    '次の資金調達のためにロングポジションとショートポジションの間で交換される支払いレート。 資金調達率がプラスの場合は、資金調達手数料を支払います。 マイナスの場合はファンディング手数料を受け取ります。',
  'Your network is not good, please reconnect your network': 'ネットワークが正しく機能していません。再接続してください',
  'Total Volume': '総取引量',
  'Top{no}': '{no}位',
  'Share {no}': '{no}賞池',
  'Futures Trading Competition': '契約取引大会',
  'Current total trading volume (USDT)': '現在の総取引量(USDT)',
  'Ends in': '終点までの距離',
  'Withdraw collateral': '証拠金引き出し',
  'Deposit collateral': '証拠金預託',
  'Open Orders': 'オープンオーダー',
  'Order History': '注文履歴',
  Bar: 'バー',
  Candle: 'ローソク足',
  Line: 'ライン',
  Area: 'エリア',
  'Hollow Candle': '中空ローソク足',
  Baseline: 'ベースライン',
  'Hi-Lo': 'ハイロー',
  O: '始値',
  H: '高値',
  L: '安値',
  C: '終値',
  'Event Rules >': 'アクティビティ規則 >',
  'Competitions will reward traders based on the volume target and rank.':
    '試合は取引量目標と順位に基づいてトレーダーに奨励される。',
  'Trade Now': '即時取引',
  'Current Total Volume': '現在の取引総額',
  'TP/SL Keep Leverage': 'TP/SL はレバレッジ倍数を維持',
  Leaderboard: 'リーダーボード',
  ROI: 'ROI',
  Million: '百万',
  PositionStatusExecuted: '実行済み：この注文は正常に実行されました。',
  PositionStatusRequest: 'リクエスト：注文リクエストは成功しましたが、注文の実行をお待ちください。',
  PositionStatusCancelled: 'キャンセル済み：この注文はキャンセルされました。',
  '15-minute waiting period for selling purchased BLP.': '購入したBLPの売却には15分の待機時間があります。',
  'System is currently upgrading...': 'システムは現在アップグレード中です...',
  'Copy Link': 'リンクをコピー',
  'Referral Code copied to your clipboard': '紹介コードがクリップボードにコピーされました',
  'Total Discount': '合計ディスカウント',
  'Total Rebate': '総リベート',
  'Total Discounts Earned by this Account as a Trader.': 'このアカウントがトレーダーとして得た総割引。',
  'Total Trading Volume with Valid Referral Codes for this Account.':
    'このアカウントが有効な紹介コードで結ばれた総取引量。',
  'Total Rebates Earned by this Account as a Referrer.': 'このアカウントが紹介者として得た総リベート 。',
  'Total Trading Volume of Recommended Traders for this Account.': 'このアカウントの推奨トレーダーの総取引量。',
  'Total Number of Traders Recommended by this Account.': 'このアカウントが推奨したトレーダーの総数。',
  Doc: '文書',
  Audit: '監査',
  'Transaction Records': '取引記録',
  'Reward Records': '報酬記録',
  'Total Amount': '合計金額',
  'Total Buy Amount': '購入総数 = 価格 * 数 + 料金について',
  'Total Sell Amount': '売却総数 = 価格 * 数 - 料金について',
  'Transaction was cancelled.': '許可を拒否されました。アプリケーションを確認してください。',
  'Market Order Beyond the slippage price and canceled.': '市場価格超過スリッページ価格。',
  handCancel: '許可を拒否されました。アプリケーションを確認してください。',
  'System Cancel': 'システムキャンセル。',
  'Error encountered during contract execution(1000).': 'システムエラー。',
  Skip: 'スキップ',
  'Phase II': '二期',
  'Exclusive benefits and rewards for you to unlock, and special rewards for new users!':
    '独自の福利厚生はロック解除を待っており、新しいユーザー専用の奨励金もあります！',
  'Will Start In': '開始',
  'So Stay Tuned!': 'お楽しみに！',
  'Invite Contest': '招待交易試合',
  'Invite to earn $2,300': '招待勝ち$2,300奨励金',
  'Trading Volume Contest': '取引量評価試合',
  'Trade Futures Volume earn $46,500': '取引勝ち$46,500奨励金',
  'Trading ROI Contest': '勝率ランキング戦',
  'Trade Futures ROI to earn $1,200': '収益評価勝ち$1,200奨励金',
  'Trading Contest': '取引コンテスト',
  'During the event period, the top 50 future traders will share $46,500 USDT prize pool, based on total trading volume reached on the blex exchange, and their future trading volume.':
    'キャンペーン期間中、公式にはblexプラットフォームの総取引量とユーザー取引量ランキングに基づいて合計$46,500 USDTプールを分割する。具体的なルールは以下の通り。',
  'Detail Rules': '詳細ルール',
  'When Total Trading Volume Reaches': '取引総額が',
  'During the event period, invite your friends to complete a future trading in any value via your invitation code/link. Both the inviting user and the invited user will get $2 USDT. The reward is limited to the first 500 valid invitations, first come first served.':
    '招待者はBLEXが中心化取引所に行って任意の金額の契約取引を完了し、招待コード/招待リンクを通じて友人を招待してBLEXが中心化取引所に行って任意の金額の契約取引を完了することを有効な招待と見なす。有効な招待を完了するたびに、招待者と招待された人はいずれも{value}奨励金を得ることができ、奨励金は上位500人の有効な招待に限り、先着順で得ることができる。',
  'Note: The maximum reward for an individual user is $20 USDT.':
    '注意：シングルは最高 {value} の招待奨励金を得ることができます。',
  'Invite Now': '今すぐ招待',
  'ROI Rank': 'ROI（収益率）ランキング',
  'During the event, $1,200 USDT will reward users according to their future trading profitability (ROI) rank. Both the top3 and top 498-500 will get $200 USDT each in this session.':
    '活動期間中、ユーザーはBLEXプラットフォームで倉庫を開けて取引し、取引利益率（ROI）ランキング、活動終了決算、{value1} 位と{value2} のユーザーによって、いずれも{value3} 奨励金を得ることができる。',
  Notices: '注意',
  '1.Users must meet the requirements during the event to be eligible for the rewards.':
    '1.ユーザーは活動要件を満たす必要があります。',
  '2.The rewards will be issued within 7 working days after the event.':
    '2.すべての奨励金は、キャンペーン終了後 {value} 営業日以内に支給されます。',
  '3.The trading volume only includes Futures trading.Trading Volume = Opened Positions + Closed Positions.':
    '3.取引量の計算は契約取引に限られている。 {value}',
  '4.Users need to accumulate at least 5U to win.': '4.ユーザーは少なくとも {value} を積算する必要があります。',
  '5.No spam is allowed, or you will be disqualified.': '5.違反ブラシ行為を禁止し、発見されると受賞資格を取り消す。',
  '6.BLEX reserves the right to the final interpretation of this event.':
    '6.今回の活動の解釈権はblexの公式所有である。',
  '7.If you have any questions, please contact our admin via':
    '7.何か問題があれば、コミュニティの開票コンサルティング管理者に参加してを助けてください',
  'Your ROI': 'あなたのROI（収益率）',
  'ROI = (Profit + UnProfit) / Cum.Collateral': '収益率ROI=（損益実現+損益未実現）/累積保証金',
  'Cum.Collateral = Total Opening position + Total Deposit Collateral': '累積保証金＝累積開倉量＋累積充填保証金',
  'Volume = Total Size (Open Size + Close Size)': '取引高=倉庫合計（開梱量+平倉庫量）',
  'Trading Volume = Opened Positions + Closed Positions.': '取引量=開倉量+平倉量。',
  'discord or telegram': 'DC',
  'In addition, the top 10 inviting users can get an additional $30 USDT reward after the event ends.':
    'アクティビティが終了し、{value} 人のユーザーを効果的に招待し、さらに {value1} のボーナスを受け取ることができます。',
  '3.The trading volume only includes Futures trading.': '3.取引量の計算は契約取引に限られている。',
  Top3: 'トップ3',
  'Bottom 3': 'ボトム3',
  'no list data': 'まだリスト情報はありません',
  'mail notice': 'メール通知',
  'mail info Add':
    'ウォレットセンターには、「メール通知」機能が追加されました。有効にすると、ポジション関連の情報をタイムリーに受け取ることができます。',
  'open mail subscrip': 'ここでは「メールサブスクリプション」機能も利用できます。',
  'got it': '了解しました',
  'provide mail account':
    'お使いのよく使われるメールアドレスを提供していただければ、いつでもポジションに関連する警告通知をお送りします。',
  'please enter email account': 'メールアドレスを入力してください。',
  'error style re enter': 'メールの形式が正しくありません。もう一度入力してください。',
  'confirm open': '有効にすることを確認',
  'close mail notice': 'メール通知を無効にする',
  'close mail info': 'メール通知を無効にすると、倉位に関する警告通知を遅れることがあります。',
  'comfir close': '無効にすることを確認',
  considerate: '考えてみてください',
  'open info': 'メール通知を有効にして、倉位情報をすみやかに受け取ってください',
  'please enter mail': 'メールアドレスを入力してください',
  'unvaild mail': 'メールの形式が正しくありません。もう一度入力してください',
  'open mail success': 'メール通知が正常に有効になりました。いつでもメールを確認してください',
  'close mail success':
    'メール通知の受信を正常にキャンセルしました。プラットフォームからはもう倉位に関連する警告通知が送られません',
  next: '次のステップ',
  'Internal JSON-RPC error.': '内部JSON-RPCエラー。',
  'Market Order Beyond the slippage price and canceled': '市場注文がスリッページ価格を超え、キャンセルされました。',
  'The index price is referenced using Chainlink + FastPrice (mainstream CEX prices), and there may be some differences from the actual transaction price.':
    '指数価格は参考価格であり、実際の取引価格が優先されます。',
  'PNL after fees': '実際の損益 = 損益 - ポジション手数料 - 資金調達手数料 - 実行手数料 - レバレッジ手数料',
  'Historical Peak': '過去のピーク',
  'Super Referrers': 'トレーダーの支援',
  'Contact us for more discounts': 'より多くの割引のためにお問い合わせください',
  'PNL / ROI': 'PNL / ROI',
  'Target Price': '目標価格',
  'Calculations are for reference only.': '計算は参考値です。',
  Calculator: '電卓',
  Calculate: '計算する',
  'The initial collateral calculation here does not include any fees or opening losses that need to be deducted.':
    'ここでの初期証拠金の計算には、差し引かれる手数料やオープン時の損失は含まれていません。',
  'PNL After Fees': '手数料差し引き後の損益',
  'Unable to reach this return rate.': 'このリターン率に到達できません。',
  'Annual interest rates are for reference only and are subject to weekly updates on Thursdays.':
    '年利率は参考値であり、具体的な更新は毎週木曜日に行われます。',
  'BTC Halving Trading Contest': 'BTCハーフトレード大会',
  'Exclusive benefits and rewards for you to unlock !': 'あなたのための特典と報酬をアンロック！',
  'Trading Master': 'グランドマスター',
  'Trading Pioneer': 'パイオニア',
  'Your PNL': 'あなたのPNL',
  'Your Trades:': 'あなたの取引：',
  'Trading Masters must not have less than 10,000 U as the opening amount in the contest account. Additional margin can be added during liquidation to ensure profitability. The selection criteria during the contest period require Trading Masters to engage in contract trading for no less than 4 days.':
    'トレーディングマスターは、コンテストアカウントのオープニング残高が {value} 未満であってはなりません。 リキデーション中に利益を確保するために追加の証拠金を追加できます。 コンテスト期間中の選択基準により、トレーディングマスターは少なくとも {value1} 間の契約取引に従事する必要があります。イベント期間中のトップ50の取引利益を持つ参加者が賞金プールを分け合います。',
  'Trading Pioneer accounts must not have less than 500 U as the opening amount. The frequency of trading during the event determines the heat ranking, and those in the top 30 ranks will share the prize pool.':
    'トレーディングパイオニアのアカウントは、オープニング残高が{value} 未満であってはなりません。 イベント中の取引頻度がヒートランキングを決定し、上位30位の参加者が賞金プールを共有します。',
  'Prize Distribution Leaderboard：': '賞金配布リーダーボード：',
  '1. Winners will be awarded an BLEX exclusive Limited edition OG Trader NFT with exclusive benefits!':
    '1. 勝者には、独占的な特典が付いたBLEX独占限定版 {value} が授与されます！',
  '2. Participants can compete in both the Trading Masters ranking and the Trading Pioneer heat ranking simultaneously but need to meet the requirements of both categories.':
    '2. 参加者は、トレーディングマスターランキングとトレーディングパイオニアヒートランキングの両方で同時に競争できますが、両方のカテゴリの要件を満たす必要があります。',
  '3. Blex reserves the right to interpret the event, and the event prizes will be distributed within the second week after the BTC halving.':
    '3. Blexは、イベントの解釈権を有し、イベントの賞品はBTCの半減後の第2週に配布されます。',
  '4 days': '4天',
  '4. Total Prize pool will be determined based on number of contest participants.':
    '4. 総賞金プールはコンテスト参加者の人数に基づいて決定されます。',
  '1,000 Participants - Prize pool $12,000': '1,000参加者 - 賞金総額 $12,000',
  '3,000 Participants - Prize pool $32,000': '3,000参加者 - 賞金総額 $32,000',
  '5,000 Participants - Prize pool $60,000': '5,000参加者 - 賞金総額 $60,000',
  '10,000 Participants - Prize pool$110,00': '10,000参加者 - 賞金総額 $110,000',
  '25,000 Participants - Prize pool $240,000': '25,000参加者 - 賞金総額 $240,000',
  '43,000 Participants - Prize pool $420,000': '43,000 Participants - Prize pool $420,000',
  '5. Users are only eligible to participate with a single wallet address. If we detect multiple wallet addresses with the same IP addresses or UIDs, all offending accounts will be disqualified.':
    '5. ユーザーは参加するために 1 つのウォレット アドレスのみを使用できます。 同じ IP アドレスを持つ複数のウォレット アドレスが検出された場合、違反しているすべてのアカウントが失格となります。',
  '6. If you invite a friend to join this contest during event period, and this friend ends up on the top 50 leaderboard, then your event PNL will be doubled and your own ranking will likely rise.':
    '6. イベント期間中に友達をこのコンテストに招待し、その友達がトップ50のリーダーボードに入った場合、あなたのイベントのPNLが倍になり、あなた自身のランキングも上がる可能性があります。',
  "7. This competition will reveal our platform's strongest Top Trader.":
    '7. このコンテストは、当社プラットフォームの最も優れたトップトレーダーを明らかにします。',
  '8. The following actions will result in immediate disqualification: profits from market manipulation, self-dealing or wash trades.':
    '8. 次の行動は即時失格の理由となります：市場操作、自己取引、または洗浄取引からの利益。',
  '9. Blex may disqualify participants without explanation.': '9. Blexは説明なしに参加者を失格にすることがあります。',
  '10. Blex reserves the right to cancel, suspend, or postpone the campaign or amend the campaign rules at our sole discretion without notice to you.':
    '10. Blexは、事前通知なしにキャンペーンをキャンセル、一時停止、延期、またはキャンペーンのルールを独自の裁量で変更する権利を留保します。',
  '11. Leaderboard rankings do not confer, guarantee, represent or imply eligibility or entitlement for any competitions, giveaways, offers or other contests (“Contest”), or reward from such Contests, during that period. Participation in any contest is subject to applicable rules and eligibility criteria, governed by the terms and conditions specific to that Contest,  and subject to confirmation at the sole and absolute discretion of Blex.':
    '11. リーダーボードのランキングは、その期間中のいかなるコンテスト、ギブアウェイ、オファーやその他のコンテスト（「コンテスト」）に対する資格や権利、またはそのようなコンテストからの報酬を、確約するものではありません。コンテストへの参加は、そのコンテストに特有のルールと資格基準に従い、Blexの単独および絶対的な裁量による確認を経て行われます。',
  '12. Blex reserves the right to change the dates and terms of the Competition at any time without prior announcement.':
    '12. Blexは事前の通知なしに、競技の日程や条件をいつでも変更する権利を留保します。',
}
