import * as Sentry from '@sentry/react'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import '@/share/toast-custom.scss'

import './global.scss'

if (process.env.NODE_ENV === 'production' && process.env.SENTRY_DSN) {
  console.log('sentry init')
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /^(https?:\/\/)?(www\.)?(dev|app)?\.?blex\.io/],
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

dayjs.extend(relativeTime)
dayjs.extend(duration)
dayjs.extend(utc)
dayjs.extend(timezone)
const dsn = process.env.SENTRY_DSN
if (process.env.ENABLE_SENTRY === 'true' && dsn) {
  Sentry.init({
    dsn,
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

//动态倒入link
const updateFavicon = (faviconPath: string) => {
  const newLink = document.createElement('link')
  newLink.rel = 'shortcut icon'
  newLink.href = faviconPath
  const existingLink = document.querySelector('link[rel="shortcut icon"]')
  if (existingLink) {
    document.head.removeChild(existingLink)
  }
  document.head.appendChild(newLink)
}

updateFavicon('assets/favicon1.ico')
