import cn from 'classnames'
import React, { useState } from 'react'
import { NavLink } from 'umi'

import s from './index.module.scss'

const BubbleDropdown = ({ items, children }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className={s.bubbleDropdown}>
      <button onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)} className={s.dropdownToggle}>
        {children}
        {isOpen && (
          <ul className={s.dropdownMenu}>
            {items.map((item, index) => (
              <li key={index} className="c200">
                {item.target === '_blank' ? (
                  <a className={cn(s.link, 'c200')} href={item.path}>
                    {item.title}
                  </a>
                ) : (
                  <NavLink className="c200" key={item.path} to={item.path}>
                    {item.title}
                  </NavLink>
                )}
              </li>
            ))}
          </ul>
        )}
      </button>
    </div>
  )
}

export default BubbleDropdown
