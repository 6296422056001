export const WEBSITE_HREF = 'https://blex.io'
export const REFERRER_CODE_HREF = 'https://blex.io/#/trade'
export const WEBSITE_HOST = 'Blex.io'
export const WEBSITE_TITLE = 'BLEX | Decentralized Perpetual Exchange'

// 外链
export enum ExternalLinks {
  GitbookFeeStructure = 'https://blex-1.gitbook.io/untitled/white-paper/fee-structure',
  AVAXNetworkTest = 'https://faucet.avax.network/',
  FMTNetworkTest = 'https://faucet.fantom.network/',
  ETHNetworkTest = 'https://faucet.quicknode.com/arbitrum/goerli/',
  TokenEconomyUsdblp = 'https://blex-1.gitbook.io/untitled/tutorial/earn',
  WhitePaperReferrals = 'https://blex-1.gitbook.io/untitled/tutorial/referral',
  WhitePaperMediaKit = 'https://blex-1.gitbook.io/untitled/media-kit',
  BlexTermsAndConditions = 'https://blex.medium.com/blex-terms-and-conditions-9b61881c58ea',
  BugBounty = 'https://blex-1.gitbook.io/untitled/bug-bounty',
  Desc = 'https://blex-1.gitbook.io/untitled/',
  zealy = 'https://zealy.io/c/blexperpetualtradingdex/questboard',
  Competitions = 'https://blex.medium.com/join-blex-futures-trading-competition-share-50-000-usdt-prize-pool-2082a1fe5d31',
}
export enum SocialLinks {
  Twitter = 'https://twitter.com/Blex_io',
  Medium = 'https://medium.com/@blex',
  Discord = 'https://discord.gg/blex-official',
  Telegram = 'https://t.me/bestblex',
  Github = 'https://github.com/dalveytech/blex-contract',
  Docs = 'https://blex-1.gitbook.io/untitled/',
}

export enum Platform {
  Binance = 'binance',
  Blex = 'blex',
  BinanceUS = 'binanceus',
  Bitmart = 'bitmart',
}

export enum Language {
  English = 'en-US',
  Japanese = 'ja-JP',
  French = 'fr-FR',
  Chinese = 'zh-CN',
  ChineseHK = 'zh-HK',
  Turkish = 'tr-TR',
}

export const options = [
  { value: Language.English, label: 'EN', display: 'English' },
  { value: Language.Japanese, label: 'JP', display: '日本語' },
  { value: Language.Chinese, label: 'ZH', display: '简体中文' },
  { value: Language.ChineseHK, label: 'HK', display: '繁體中文' },
  { value: Language.Turkish, label: 'TR', display: 'Turkish' },
]

export const LINKS = {
  Overview: '/home',
  Competitions: '/more/competitions',
}

export const PRICE_DEFAULT_DECIMALS = 30

export const LOCALSTORAGE_KEYS = {
  HiddenLimitDistrictModal: 'hidden-limit-district-modal',
}
