import React, { FC, PropsWithChildren } from 'react'
import { Link } from 'umi'

import navLogo from '@/assets/nav_logo.svg'
import navLogoH5 from '@/assets/nav_logo_h5.svg'

import { useEventTracking } from '@/lib/bury'
import { useMediaScreen } from '@/lib/hooks/useMedia'
import { LINKS } from '@/share/consts'

import s from './index.module.scss'

export interface IHeader {}

const Header: FC<PropsWithChildren<IHeader>> = (props) => {
  const { children } = props
  const trackEvent = useEventTracking()
  const handleLink = () => {
    trackEvent('nav_click_logo', { event_name: 'guide_close' })
  }
  return (
    <header className={s.header}>
      <Link className={s.logo} to={LINKS.Overview} replace onMouseDown={handleLink}>
        <img src={navLogoH5} alt="logo" className={s.h5_logo} />
        <img src={navLogo} alt="logo" className={s.nav_logo} />
      </Link>
      {children}
    </header>
  )
}
export default Header
